import { mapSectionsWithClassroomLabels } from "../../../Mapper/TeacherMapper";
import { mapLevelLabels } from "../../../Mapper/LevelMapper";
import { mapperStudentsData } from "../../../Mapper/StudentMapper";
import ClassRoomsRepository from "../../repository/ClassroomsRepository";
import LevelsRepository from "../../repository/LevelsRepository";
import SectionsRepository from "../../repository/SectionsRepository";
import StudentsRepository from "../../repository/StudentsRepository";
import ClassroomsService from "../../services/ClassroomsService";
import LevelsService from "../../services/LevelsService";
import SectionsService from "../../services/SectionsService";
import StudentsService from "../../services/StudentsService";
import useFetch from "../utils/useFetch";

const createRepositories = (db) => ({
  classrooms: new ClassRoomsRepository(db),
  sections: new SectionsRepository(db),
  students: new StudentsRepository(db),
  levels: new LevelsRepository(db),
});

const createServices = (repositories) => ({
  classrooms: new ClassroomsService(repositories.classrooms),
  sections: new SectionsService(repositories.sections),
  students: new StudentsService(repositories.students),
  levels: new LevelsService(repositories.levels),
});

const fetchData = async (schoolId) => {
  const repositories = createRepositories();
  const services = createServices(repositories);
  const students = await services.students.getStudentsBySchoolId(schoolId);
  const classes = await services.classrooms.getClassroomsBySchoolId(schoolId);
  const sections = await fetchSections(classes, services.sections);
  const levels = await services.levels.getLevels();
  const sectionsLabel = mapSectionsWithClassroomLabels(sections, classes);
  const levelLabels = mapLevelLabels(levels);
  const schoolData = { sections, levels, classes, sectionsLabel, levelLabels };
  const studentsData = mapperStudentsData({ students, ...schoolData });
  return { students: studentsData, schoolData };
};

const fetchSections = async (classrooms, sectionsService) => {
  if (!classrooms?.length) return [];

  const sectionsPromises = classrooms.map((classroom) =>
    sectionsService.getSectionsByClassId(classroom.id)
  );
  const sections = await Promise.all(sectionsPromises);
  return sections.flat();
};

const useSchoolStudents = (schoolId) => {
  const {
    data: studentsData,
    isLoading: isStudentsLoading,
    error: studentsError,
    setData,
  } = useFetch(() => fetchData(schoolId));
  return { isStudentsLoading, studentsError, studentsData, setData };
};

export default useSchoolStudents;
