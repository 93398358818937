import React from "react";
import AssessmentRoundRepository from "../../data/repository/AssessmentRoundRepository";
import SchoolsRepository from "../../data/repository/SchoolsRepository";
import AssessmentRoundService from "../../data/services/AssessmentRoundService";
import SchoolsService from "../../data/services/SchoolsService";
import { ToastMessage } from "../Toast/ToastMessage";
import CreateEditForm from "../Forms/CreateEditForm";
import { RoundFormSchema } from "../../utils/validationSchema";
import Modal from "../Modals/Modal";

const AssessmentRound = ({ open, setOpen, schoolId, setData }) => {
  const roundRepository = new AssessmentRoundRepository();
  const roundService = new AssessmentRoundService(roundRepository);
  const schoolRepository = new SchoolsRepository();
  const schoolService = new SchoolsService(schoolRepository);

  const addNewRound = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");

    try {
      setOpen(false);

      const params = { schoolId, ...data };
      const assessmentRound = await roundService.addAssessmentRound(params);
      const postData = {
        round: assessmentRound.id,
        updatedAt: new Date(),
      };
      await schoolService.updateSchool(schoolId, postData);
      ToastMessage.update(
        toastId,
        "success",
        "تم إضافة دورة تقييم جديدة بنجاح"
      );

      setData((prev) => ({
        ...prev,
        roundData: { ...prev.roundData, title: { ar: data.name } },
      }));
    } catch (error) {
      console.error("Error adding new Assessment Round:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء إضافة دورة التقييم");
    }
  };
  return (
    <Modal
      open={open}
      close={() => setOpen(false)}
      title={"اضافة دورة تقييم جديدة"}
    >
      <CreateEditForm
        onSubmit={addNewRound}
        schemaType={RoundFormSchema}
        onCancelClick={() => setOpen(false)}
        titleSubmit={"حفظ وبدأ"}
        inputFields={[
          { name: "name", label: "اسم دورة التقييم", type: "text" },
          { name: "startDate", label: "تاريخ البداية", type: "date" },
          { name: "endDate", label: "تاريخ النهاية", type: "date" },
        ]}
      />
    </Modal>
  );
};

export default AssessmentRound;
