import React from "react";
import levelIcon from "../../../assets/images/svg/rocket.svg";
import clockIcon from "../../../assets/images/svg/clock.svg";
import lightIcon from "../../../assets/images/svg/light.svg";
import targetIcon from "../../../assets/images/svg/target.svg";
import globeIcon from "../../../assets/images/svg/globe.svg";
import PreviousStatsCard from "./PreviousStatsCard";
import CountUpStats from "./CountUpStats";
import { t } from "i18next";

const PreviousSessionStats = ({ session, textLevel }) => {
  const fluencyScore = (fScore) => {
    switch (fScore) {
      case 0:
        return "طلاقة ضعيفة";
      case 1:
        return "طلاقة مَحدودة";
      case 2:
        return "طلاقة جيِّدة";
      default:
        return "طلاقة ممتازة";
    }
  };

  const dataStats = [
    {
      label: "مستوى النص",
      icon: levelIcon,
      value: textLevel?.code,
      render: ({ value }) => value,
    },
    {
      label: t("session.duration"),
      icon: clockIcon,
      value: session?.reading?.duration,
      suffix: " " + t("session.seconds"),
      render: ({ value, suffix }) => (
        <CountUpStats value={value} suffix={suffix} />
      ),
    },
    {
      label: t("session.comprehensionAndPerception"),
      icon: lightIcon,
      value: session?.results?.comprehensionScore,
      suffix: "/9",
      render: ({ value, suffix }) => (
        <CountUpStats value={value} suffix={suffix} />
      ),
    },
    {
      label: "دقة القراءة",
      icon: targetIcon,
      value: session?.results?.accuracyScore,
      suffix: "%",
      render: ({ value, suffix }) => (
        <CountUpStats value={value} suffix={suffix} />
      ),
    },
    {
      label: "طلاقة القراءة",
      icon: globeIcon,
      value: fluencyScore(session?.results?.fluencyScore),
      render: ({ value }) => value,
    },
  ];

  return (
    <div className="card crm-widget normal-box-shadow">
      <div className="row row-cols-xxl-5 row-cols-md-5 row-cols-1 g-0">
        {dataStats.map((item, index) => {
          return (
            <PreviousStatsCard
              key={index}
              icon={item.icon}
              render={item.render}
              value={item.value}
              suffix={item.suffix}
              label={item.label}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PreviousSessionStats;
