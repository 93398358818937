import React, { useState } from "react";
import { getPaginationRowModel, getCoreRowModel } from "@tanstack/react-table";
import { getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { getSortedRowModel, flexRender } from "@tanstack/react-table";
import { ReactComponent as Sort } from "../../assets/images/icons/sort.svg";
import { ReactComponent as SortUp } from "../../assets/images/icons/sort-up.svg";
import { ReactComponent as SortDown } from "../../assets/images/icons/sort-down.svg";
import TablePagination from "./TablePagination";
import SearchBar from "./SearchBar";
import { Table } from "reactstrap";
import NotFoundResult from "./NotFoundResult";
import TableDataLoading from "./TableDataLoading";

const BasicTable = ({
  data,
  columns,
  tableClass,
  theadClass,
  divClass,
  trClass,
  thClass,
  rowClick,
  isLoading,
  handleTdClick,
  customPageSize,
  header,
  isGlobalFilter = true,
  messageNotFoundResult,
}) => {
  const [filtering, setFiltering] = useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    columnResizeMode: "onChange",
    initialState: {
      pagination: { pageSize: customPageSize || 10 },
    },
    state: {
      globalFilter: filtering,
    },
    onGlobalFilterChange: setFiltering,
  });

  const { pageIndex, pageSize } = table.getState().pagination;
  const lengthRow = table.getPrePaginationRowModel().rows.length;

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between my-4">
        {isGlobalFilter && (
          <SearchBar
            value={filtering}
            setValue={setFiltering}
            lengthRow={lengthRow}
            maxWidth={260}
          />
        )}
        <div>{header}</div>
      </div>

      <div className={divClass}>
        <Table className={tableClass}>
          <thead className={theadClass}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={trClass}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={thClass}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <span style={{ userSelect: "none" }}>
                      {header.column.columnDef.header}
                      <span style={{ marginInline: 10 }}>
                        {
                          {
                            asc: <SortUp width={15} height={15} />,
                            desc: <SortDown width={15} height={15} />,
                            false: header.column.getCanSort() && (
                              <Sort width={15} height={15} />
                            ),
                          }[header.column.getIsSorted()]
                        }
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {isLoading && <TableDataLoading columnsLength={columns.length} />}

            {!isLoading && lengthRow === 0 && (
              <NotFoundResult
                message={messageNotFoundResult}
                columnsLength={columns.length}
              />
            )}

            {!isLoading &&
              table.getRowModel().rows.map((row) => (
                <tr key={row.id} onClick={rowClick}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} onClick={(e) => handleTdClick(e, cell)}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>

        <TablePagination
          table={table}
          pageIndex={pageIndex}
          pageSize={pageSize}
          isLoading={isLoading}
        />
      </div>
    </React.Fragment>
  );
};

export default BasicTable;
