import React, { useState } from "react";
import { Card, Col, Row, Accordion, AccordionItem } from "reactstrap";
import AccordionSessionHeader from "./Accordion/AccordionSessionHeader";
import useAssessmentTexts from "../../data/hooks/useAssessmentTexts";
import AccordionSessionBody from "./Accordion/AccordionSessionBody";
import PreviousSessionStats from "./SessionStats/PreviousSessionStats";
import ErrorDisplay from "../Error/ErrorDisplay";
import ClipLoader from "../Loading/ClipLoader";

const PreviousSessions = ({ session, miscueList, keyItem, getLevel }) => {
  const [isOpen, setIsOpen] = useState("");
  const handleToggle = (id) => setIsOpen(isOpen === id ? "" : id);

  const assessmentTextId = session.assessmentText;
  const {
    isAssessmentTextsLoading,
    assessmentTextsError,
    assessmentTextsData,
  } = useAssessmentTexts(assessmentTextId);

  if (assessmentTextsError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching sections"}
        errorMessage={assessmentTextsError}
      />
    );
  }

  if (isAssessmentTextsLoading) {
    return <ClipLoader />;
  }

  const sessionDate = session?.createdAt;
  const textLevel = getLevel(assessmentTextsData?.level);

  return (
    <React.Fragment>
      <Accordion key={keyItem} open={isOpen} toggle={handleToggle}>
        <AccordionItem className="session-item-block-area">
          <Row key={keyItem}>
            <Col md={12}>
              <Card>
                <AccordionSessionHeader date={sessionDate} targetId={keyItem} />

                <AccordionSessionBody
                  session={session}
                  assessmentText={assessmentTextsData}
                  keyItem={keyItem}
                  miscueList={miscueList}
                >
                  <PreviousSessionStats
                    session={session}
                    textLevel={textLevel}
                  />
                </AccordionSessionBody>
              </Card>
            </Col>
          </Row>
        </AccordionItem>
      </Accordion>
    </React.Fragment>
  );
};

export default PreviousSessions;
