import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import StudentMistakes from "./StudentMistakes";
import StudentProgressLevels from "./StudentProgressLevels";
import LatestSession from "./LatestSession";
import PreviousSessions from "./PreviousSessions";
import { t } from "i18next";

const SessionsDetails = ({ sessionsList, levelsList, behaviorTypesList }) => {
  const getMistake = (id) => {
    return behaviorTypesList.find((mis) => mis.id === id);
  };

  const getLevel = (id) => {
    const level = levelsList.find((level) => level.id === id);
    return level;
  };

  const latestSession = sessionsList[0];
  const previousSessions = sessionsList.slice(1);

  return (
    <div className="sessions-bg-card">
      <div className="pt-2">
        <Row>
          <Col md={12}>
            <Row>
              <StudentProgressLevels
                sessionsList={sessionsList}
                levelsList={levelsList}
              />
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <StudentMistakes
                  LastSession={latestSession}
                  getMistake={getMistake}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        {sessionsList && sessionsList.length > 0 && (
          <>
            <h5 className="card-title mb-4">تقييم اخر جلسة</h5>

            <LatestSession
              getLevel={getLevel}
              miscueList={behaviorTypesList}
              session={latestSession}
            />

            <h5 className="card-title mb-4">{t("profile.lastSession")}</h5>
            <div className="accordion-sessions-list-items">
              {previousSessions?.length ? (
                previousSessions.map((session, index) => (
                  <PreviousSessions
                    key={index}
                    keyItem={`${index}`}
                    getLevel={getLevel}
                    session={session}
                    miscueList={behaviorTypesList}
                  />
                ))
              ) : (
                <h5 className="text-center p-2">لا يوجد جلسات أخرى</h5>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SessionsDetails;
