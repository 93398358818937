import {
  enhancedTeachers,
  mapSectionsWithClassroomLabels,
} from "../../../Mapper/TeacherMapper";
import DatabaseService from "../../../service/DatabaseService";
import FirebaseClassRoomsRepository from "../../repository/ClassroomsRepository";
import SectionsRepository from "../../repository/SectionsRepository";
import UsersRepository from "../../repository/UsersRepository";
import ClassroomsService from "../../services/ClassroomsService";
import SectionsService from "../../services/SectionsService";
import UsersService from "../../services/UsersService";
import useFetch from "../utils/useFetch";

const createRepositories = (db) => ({
  users: new UsersRepository(db),
  classrooms: new FirebaseClassRoomsRepository(db),
  sections: new SectionsRepository(db),
});

const createServices = (repositories) => ({
  users: new UsersService(repositories.users),
  classrooms: new ClassroomsService(repositories.classrooms),
  sections: new SectionsService(repositories.sections),
});

const fetchData = async (schoolId) => {
  const dbService = new DatabaseService();
  const repositories = createRepositories(dbService.db);
  const services = createServices(repositories);

  const users = await services.users.getUsersBySchoolId(schoolId);
  const classrooms = await services.classrooms.getClassroomsBySchoolId(
    schoolId
  );
  const sections = await fetchSections(classrooms, services.sections);
  const sectionsLabels = mapSectionsWithClassroomLabels(sections, classrooms);
  const teachers = enhancedTeachers(users, sectionsLabels);

  return { teachers, sectionsLabels, sections };
};

const fetchSections = async (classrooms, sectionsService) => {
  if (!classrooms?.length) return [];

  const sectionsPromises = classrooms.map((classroom) =>
    sectionsService.getSectionsByClassId(classroom.id)
  );
  const sections = await Promise.all(sectionsPromises);
  return sections.flat();
};

const useTeachers = (schoolId) => {
  const {
    data: teachersData,
    isLoading: isTeachersLoading,
    error: teachersError,
    setData,
  } = useFetch(() => fetchData(schoolId));
  return { isTeachersLoading, teachersError, teachersData, setData };
};

export default useTeachers;
