import axios from "axios";

class BackendService {
  constructor(collection) {
    this.collection = collection;
    this.baseUrl = process.env.REACT_APP_KAT_BACKEND_BASE_URL;
    this.instance = axios.create();
  }

  setAccessToken(accessToken) {
    localStorage.setItem("ACCESS_TOKEN", accessToken);
  }

  getAccessToken() {
    return localStorage.getItem("ACCESS_TOKEN");
  }

  collectionUrl(collectionName) {
    return this.baseUrl + collectionName + "/";
  }

  async accessToken(credentials) {
    const apiUrl = this.baseUrl + "auth/token";
    const { data } = await this.instance.post(apiUrl, credentials);

    if (data.access_token != null) {
      return data.access_token;
    } else {
      return null;
    }
  }

  async requestAccessToken(credentials) {
    const accessToken = await this.accessToken(credentials);
    if (accessToken) {
      this.setAccessToken(accessToken);
    } else {
      this.setAccessToken(null);
    }
  }

  async getAll() {
    const apiUrl = this.collectionUrl(this.collection);
    const accessToken = this.getAccessToken();
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await this.instance.get(apiUrl, options);
    const updatedData = data.map((doc) => ({
      ...doc,
      id: doc._id,
    }));
    return updatedData;
  }

  async getOne(id) {
    const apiUrl = this.collectionUrl(this.collection) + id;
    const accessToken = this.getAccessToken();
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await this.instance.get(apiUrl, options);
    const updatedData = {
      ...data,
      id: data._id,
    };
    return updatedData;
  }

  async getMany(ids) {
    const apiUrl = this.collectionUrl(this.collection);
    const accessToken = this.getAccessToken();
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const postData = { ids: ids };
    const { data } = await this.instance.post(apiUrl, postData, options);
    const updatedData = data.map((doc) => ({
      ...doc,
      id: doc._id,
    }));
    return updatedData;
  }

  async getManyByFieldId(field, id) {
    const apiUrl = this.collectionUrl(this.collection);
    const accessToken = this.getAccessToken();
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const postData = { [field]: id };
    const { data } = await this.instance.post(apiUrl, postData, options);
    const updatedData = data.map((doc) => ({
      ...doc,
      id: doc._id,
    }));
    return updatedData;
  }

  async getManyByFilter(filterObj) {
    const apiUrl = this.collectionUrl(this.collection);
    const accessToken = this.getAccessToken();
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await this.instance.post(apiUrl, filterObj, options);
    const updatedData = data.map((doc) => ({
      ...doc,
      id: doc._id,
    }));
    return updatedData;
  }

  async addOne(postData) {
    const apiUrl = this.collectionUrl(this.collection);
    const accessToken = this.getAccessToken();
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await this.instance.put(apiUrl, postData, options);
    return data;
  }

  async deleteOne(id) {
    const apiUrl = this.collectionUrl(this.collection) + id;
    const accessToken = this.getAccessToken();
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    await this.instance.delete(apiUrl, options);
  }

  async updateOne(id, postData) {
    const apiUrl = this.collectionUrl(this.collection) + id;
    const accessToken = this.getAccessToken();
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await this.instance.put(apiUrl, postData, options);
    return data;
  }
}
export default BackendService;
