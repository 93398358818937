import { convertTimestamp } from "../utils/convertTimestamp";

const findById = (array, id) => array.find((item) => item.id === id);
const findLabel = (array, id) => array.find((item) => item.value === id);

const findLatestSession = (sessions, studentId) => {
  return sessions
    ?.filter((session) => session.student === studentId)
    .sort((a, b) => b?.createdAt?.seconds - a?.createdAt?.seconds)[0];
};

const getAssessmentStatus = (rounds, roundId, currentDate) => {
  const round = findById(rounds, roundId);
  return round && round?.endDate?.seconds > currentDate ? "expired" : "normal";
};

const fractionToPercentage = (fraction, denominator = 9) => {
  if (!fraction) return null;
  return Math.round((fraction / denominator) * 100);
};

const score = (score) => (score ? score + "%" : "-");
const isEmpty = (value) => (value ? value : "-");

export const convertStudentsData = (students, sessions, levels, rounds) => {
  return students?.map((student) => {
    const { results, round } = findLatestSession(sessions, student?.id) ?? {};
    const levelDetails = findById(levels, student?.level);
    const currentDate = student?.lastAssessment;
    const lastAssessmentDate = convertTimestamp(currentDate);
    const comprehension = fractionToPercentage(results?.comprehensionScore);

    const status = getAssessmentStatus(rounds, round?.id, currentDate);

    return {
      ...student,
      sessionResult: {
        accuracyScore: score(results?.accuracyScore),
        comprehensionScore: score(comprehension),
        fluencyScore: score(results?.fluencyScore),
        round: round?.id,
      },
      level: isEmpty(levelDetails),
      lastAssessment: { date: isEmpty(lastAssessmentDate), status },
    };
  });
};

const mapStudentData = ({
  student,
  sections,
  levels,
  classes,
  sectionsLabel,
}) => {
  const level = findById(levels, student?.level);
  const section = findById(sections, student?.section);
  const classroom = findById(classes, section?.classroom);
  const numberOfAssessment = Object.values(student?.previousAttempts || {});

  return {
    ...student,
    level: { ...level },
    section: { name: section?.name, id: section?.id },
    classroom: { name: classroom?.name, id: classroom?.id },
    lastAssessment: convertTimestamp(student?.lastAssessment),
    numberOfAssessment: numberOfAssessment?.length,
    sections: findLabel(sectionsLabel, student?.section?.id),
  };
};

export const mapperStudentsData = ({ students, ...rest }) =>
  students.map((student) => mapStudentData({ student, ...rest }));

export const mapperStudentData = ({ student, ...rest }) =>
  mapStudentData({ student, ...rest });
