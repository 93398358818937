import BackendService from "../../service/BackendService";

class StudentsRepository {
  constructor() {
    this.backendService = new BackendService("students");
  }
  async _getStudentsByRef(query) {
    const snapshot = await this.collection
      .where(query.field, query.operator, query.value)
      .get();
    const students = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return students;
  }

  async getStudentsByRefSections(RefSections) {
    const promises = RefSections.map((section) =>
      this.collection.where("section", "==", section).get()
    );

    const results = await Promise.allSettled(promises);

    const students = results
      .filter((result) => result.status === "fulfilled")
      .flatMap((result) => result.value.docs)
      .map((doc) => ({ data: doc.data(), id: doc.id }));

    return students;
  }

  async getStudentById(id) {
    return await this.backendService.getOne(id);
  }

  async getStudentBySectionId(id) {
    return await this.backendService.getManyByFieldId("section", id);
  }

  async getStudentsBySchoolId(id) {
    return await this.backendService.getManyByFieldId("school", id);
  }

  async verifyStudentId(id) {
    if (!id) return null;
    const doc = await this.collection.doc(id).get();
    return doc.exists;
  }

  async addStudent(params) {
    return await this.backendService.addOne(params);
  }

  async updateStudent(id, params) {
    return await this.backendService.updateOne(id, params);
  }

  async deleteStudent(id) {
    return await this.backendService.deleteOne(id);
  }
}

export default StudentsRepository;
