import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ title, getLabel, data, tooltipName, style }) => {
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <h5 className="card-title mb-3">{title}</h5>
          <div className="mb-3">
            <ReactApexChart
              options={{
                chart: {
                  height: 450,
                  type: "line",
                  stacked: true,
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: false,
                  },
                  toolbar: {
                    autoSelected: "zoom",
                    tools: {
                      download: false,
                      selection: false,
                      pan: false,
                      reset: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                    },
                  },
                },
                markers: {
                  size: 6,
                },
                dataLabels: {
                  enabled: true,
                  formatter: (value) => getLabel(value),
                  offsetY: -10,
                  style: {
                    fontSize: style.fontSize,
                  },
                },
                stroke: {
                  curve: "straight",
                },
                colors: [style.color],
                tooltip: {
                  shared: false,
                  y: {
                    formatter: (value) => getLabel(value),
                  },
                },
                xaxis: {
                  type: "datetime",
                  labels: {
                    offsetX: -10,
                    show: true,
                    style: {
                      fontSize: style.fontSize,
                      fontWeight: style.fontWeight,
                    },
                  },
                },
                yaxis: {
                  opposite: true,
                  tickAmount: data.length > 13 ? data.length / 2 : data.length,
                  showAlways: true,
                  labels: {
                    offsetX: -10,
                    show: true,
                    style: {
                      fontSize: style.fontSize,
                      fontWeight: style.fontWeight,
                    },
                    formatter: (value) => getLabel(value),
                  },
                },
              }}
              series={[
                {
                  name: tooltipName,
                  data: data?.map((ele) => {
                    return {
                      x: ele.id,
                      y: ele.value,
                    };
                  }),
                },
              ]}
              type="line"
              height="450"
              className="apex-charts"
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LineChart;
