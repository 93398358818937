import BehaviorTypesRepository from "../repository/BehaviorTypesRepository";
import BehaviorTypesService from "../services/BehaviorTypesService";
import useFetch from "./utils/useFetch";

const fetchData = async () => {
  const behaviorTypesRepository = new BehaviorTypesRepository();
  const behaviorTypesService = new BehaviorTypesService(
    behaviorTypesRepository
  );
  const behaviorTypes = await behaviorTypesService.getBehaviorTypes();
  return behaviorTypes;
};

const useBehaviorTypes = () => {
  const {
    data: behaviorTypesData,
    isLoading: isBehaviorTypesLoading,
    error: behaviorTypesError,
  } = useFetch(() => fetchData());
  return { isBehaviorTypesLoading, behaviorTypesError, behaviorTypesData };
};

export default useBehaviorTypes;
