import Loader from "../Common/Loader";

const TableDataLoading = ({ columnsLength }) => {
  return (
    <tr>
      <td colSpan={columnsLength} className="loading-data">
        <Loader color="#6ac3db" size={2.5} />
      </td>
    </tr>
  );
};

export default TableDataLoading;
