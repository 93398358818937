import BackendService from "../../service/BackendService";
import { getFirebaseService } from "../../service/FirebaseAuthService";
import {
  createFirebaseUser,
  updateFirebaseUser,
  verifyUserEmail,
} from "../../service/FirebaseFunctions";
import RolesRepository from "./RolesRepository";

class UsersRepository {
  constructor() {
    this.backendService = new BackendService("users");
  }

  async getUser(userId) {
    const userData = await this.backendService.getOne(userId);
    return userData;
  }

  async getUserByFilter(filter) {
    const data = await this.backendService.getManyByFilter(filter);
    const userData = data[0];

    return userData;
  }

  async updateLastLogin(userId) {
    await this.collection.doc(userId).update({ lastLogin: new Date() });
  }

  async addUser(params) {
    const userExists = await verifyUserEmail({ email: params.email });
    if (userExists) throw Error("This email has already been used");

    const user = await createFirebaseUser(params.email, params.password);
    const role = params.role;
    const school = params.school;
    const sections = params?.sections?.map((section) => section.value);

    const userData = {
      uid: user.uid,
      firstName: params.firstName,
      lastName: params.lastName,
      email: params.email,
      role: role,
      school: school,
      sections: sections,
      status: "active",
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    return await this.backendService.addOne(userData);
  }

  async updateCurrentUser(userId, params) {
    const userExists = await verifyUserEmail({ email: params.email });

    if (userExists && params.currentEmail !== params.email) {
      throw new Error("This email has already been used");
    }
    const authService = getFirebaseService();
    try {
      if (params.currentEmail !== params.email) {
        await authService.updateUserEmail(params.email);
      }
    } catch (error) {
      console.error(error);
      throw new Error("Error updating user credentials");
    }

    delete params.currentEmail;
    const userData = {
      ...params,
      updatedAt: new Date(),
    };

    return await this.backendService.updateOne(userId, userData);
  }

  async updateUser(userId, params) {
    const userExists = await verifyUserEmail({ email: params.email });

    if (userExists && params.currentEmail !== params.email)
      throw Error("This email has already been used");

    const sections = params?.sections?.map((section) => section.value);

    try {
      await updateFirebaseUser({ email: params.email, uid: userId });
    } catch (error) {
      console.error(error);
      throw new Error("Error updating user");
    }

    const userData = {
      firstName: params.firstName,
      lastName: params.lastName,
      email: params.email,
      sections: sections,
      updatedAt: new Date(),
    };

    return await this.backendService.updateOne(userId, userData);
  }

  async addNetworkAdminSchool(userId, schools) {
    const userData = {
      schools: schools,
      updatedAt: new Date(),
    };

    await this.backendService.updateOne(userId, userData);
  }

  async updateUserParams(id, params) {
    await this.backendService.updateOne(id, params);
  }

  async deleteUser(userId) {
    await this.backendService.deleteOne(userId);
  }

  async addSectionToUser({ userId, currentSections = [], newSections }) {
    const sectionReferences = newSections.map((section) => section.value);
    const sections = [...sectionReferences, ...currentSections];

    const data = {
      sections: sections,
      updatedAt: new Date(),
    };

    await this.backendService.updateOne(userId, data);
    return sections;
  }

  async removeSectionFromUser({ userId, sections }) {
    const data = {
      sections: sections,
      updatedAt: new Date(),
    };

    await this.backendService.updateOne(userId, data);
  }

  async _getUsersByRef(query, role) {
    const snapshot = await this.collection
      .where(query.field, query.operator, query.value)
      .where("role", "==", this.db.doc(`roles/${role}`))
      .get();
    const students = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return students;
  }

  async getUsersBySchoolId(schoolId, role = "teacher") {
    const rolesRepository = new RolesRepository();
    const roleDoc = await rolesRepository.findByRole(role);
    const roleId = roleDoc[0]["id"];

    const filterObj = {
      school: schoolId,
      role: roleId,
    };

    const users = await this.backendService.getManyByFilter(filterObj);
    return users;
  }
}

export default UsersRepository;
