import { ROLES } from "../../utils/roles";
import { t } from "i18next";

export const getSectionBreadcrumbs = (
  userRole,
  schoolName,
  schoolId,
  className,
  sectionName,
  classId
) => {
  const breadcrumbs = [];

  if (userRole === ROLES.SUPER_ADMIN) {
    breadcrumbs.push({ label: "المدارس", link: `/` });
  }

  if (userRole === ROLES.SUPER_ADMIN || userRole === ROLES.SCHOOL_ADMIN) {
    breadcrumbs.push(
      { label: schoolName || "", link: `/schools/${schoolId}` },
      { label: "الصفوف", link: `/schools/${schoolId}/classrooms` },
      {
        label: className || "",
        link: `/schools/${schoolId}/classrooms/${classId}`,
      },
      { label: sectionName || "" }
    );
  }

  if (userRole === ROLES.TEACHER) {
    breadcrumbs.push(
      { label: "صفوفي", link: "/classrooms" },
      { label: `${className || ""}: ${sectionName || ""}`, active: true }
    );
  }

  return breadcrumbs;
};

export const getStudentBreadcrumbs = (
  userRole,
  schoolName,
  schoolId,
  className,
  sectionName,
  classId,
  sectionId,
  studentName
) => {
  const breadcrumbs = [];

  if (userRole === ROLES.SUPER_ADMIN) {
    breadcrumbs.push({ label: "المدارس", link: `/` });
  }

  if (userRole === ROLES.SUPER_ADMIN || userRole === ROLES.SCHOOL_ADMIN) {
    breadcrumbs.push(
      { label: schoolName || "", link: `/schools/${schoolId}` },
      { label: "الصفوف", link: `/schools/${schoolId}/classrooms` },
      {
        label: className || "",
        link: `/schools/${schoolId}/classrooms/${classId}`,
      },
      {
        label: sectionName || "",
        link: `/schools/${schoolId}/classrooms/${classId}/sections/${sectionId}`,
      },
      {
        label: studentName || "",
        active: true,
      }
    );
  }

  if (userRole === ROLES.TEACHER) {
    breadcrumbs.push(
      { label: "صفوفي", link: "/classrooms" },
      { label: className || "" },
      { label: t("addStudent.sections") },
      {
        label: sectionName || "",
        link: `/classrooms/${classId}/sections/${sectionId}`,
      },
      { label: studentName || "", active: true }
    );
  }

  return breadcrumbs;
};
