import AssessmentRoundRepository from "../../repository/AssessmentRoundRepository";
import SchoolsRepository from "../../repository/SchoolsRepository";
import AssessmentRoundService from "../../services/AssessmentRoundService";
import SchoolsService from "../../services/SchoolsService";
import useFetch from "../utils/useFetch";

const fetchData = async (id) => {
  const schoolRepository = new SchoolsRepository();
  const schoolService = new SchoolsService(schoolRepository);
  const school = await schoolService.getSchoolById(id);
  const roundId = school?.round;
  const roundRepository = new AssessmentRoundRepository();
  const roundService = new AssessmentRoundService(roundRepository);
  const roundData = await roundService.getAssessmentRoundById(roundId);

  return { roundData, ...school };
};

const useSchool = (id) => {
  const {
    data: schoolData,
    isLoading: isSchoolLoading,
    error: schoolError,
    setData,
  } = useFetch(() => fetchData(id));
  return { isSchoolLoading, schoolError, schoolData, setData };
};

export default useSchool;
