import React from "react";
import { Col, Container, Row } from "reactstrap";
import tree from "../../../assets/images/login-tree.png";
import grass from "../../../assets/images/login-grass.png";
import cloud from "../../../assets/images/login-grass-top-right.svg";
import leftCloud from "../../../assets/images/login-cloud-top-left.png";
import logoVertical from "../../../assets/images/logo-vertical.png";

const BgScreenAuth = ({ children }) => {
  return (
    <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
      <div className="bg-overlay"></div>

      <img src={tree} alt="Tree" className="img-bottom-right-tree" />
      <img src={grass} alt="Grass" className="img-bottom-left-tree" />
      <img src={cloud} alt="Grass" className="img-top-right-tree" />
      <img src={leftCloud} alt="Grass" className="img-top-left-tree" />

      <div className="auth-page-content overflow-hidden">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col lg={5}>
              <img src={logoVertical} alt="Kutubee" className="logo-vertical" />

              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BgScreenAuth;
