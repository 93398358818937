import { useEffect } from "react";
import ReactTooltip from "react-tooltip";

const SessionText = ({ session, miscueList, sessionAssessment }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getMistake = (id) => {
    return miscueList.find((mis) => mis.id === id);
  };

  const validateAssessmentText = (text) => {
    if (session?.reading?.behaviors) {
      const splittedContent = text
        .split(/(\s|\.\n|\.\\.\\n|\.\n|\\n|\n|\\n\\n|.\\n\\n|!\\n\\n|؟\\n\\n)/g)
        .filter((word) => word.trim() !== "");

      let nonErrorWords = [];

      const processedContent = splittedContent.map((word, index) => {
        if (
          word === "." ||
          word === "\n" ||
          word === "\\n" ||
          word === ".\\n" ||
          word == "\\n\\n" ||
          word == ".\\n\\n" ||
          word == "!\\n\\n" ||
          word == "؟\\n\\n"
        ) {
          if (word === "\n" || word === "\\n") {
            word = "<br />";
          } else if (
            word == "\\n\\n" ||
            word == ".\\n\\n" ||
            word == "!\\n\\n" ||
            word == "؟\\n\\n"
          ) {
            word = "<br /><br />";
          }
          return word;
        } else {
          nonErrorWords.push(word);

          session.reading.behaviors.forEach((err) => {
            if (err.index === nonErrorWords.length - 1) {
              const { title, color } = getMistake(err.behavior);
              word = `<span data-tip="${title}" class="show-error-word" style="background-color: ${color}">${word}</span>`;
            }
          });

          return word;
        }
      });

      return processedContent.join(" ");
    } else {
      return text;
    }
  };

  return (
    <>
      <h4 className={"heading-session"}>{session?.title}</h4>

      <div style={{ overflowY: "auto" }} className="wrap">
        <ReactTooltip />

        {sessionAssessment?.text !== undefined && (
          <h5
            style={{
              lineHeight: "48px",
            }}
            dangerouslySetInnerHTML={{
              __html: validateAssessmentText(sessionAssessment?.text),
            }}
          ></h5>
        )}
      </div>
    </>
  );
};

export default SessionText;
