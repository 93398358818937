import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function DropdownButton({ items, className, button }) {
  return (
    <UncontrolledDropdown direction="down">
      <DropdownToggle className={className}>{button}</DropdownToggle>

      <DropdownMenu className="menu-dropdown">
        {items.map((item, index) => (
          <DropdownItem
            key={index}
            onClick={item.onClick}
            className="d-flex  align-items-center gap-2"
          >
            {item.icon}
            <span>{item.text}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default DropdownButton;
