import LineChart from "../Charts/LineChart";
import { t } from "i18next";
import { fromUnixTime } from "date-fns";

const StudentProgressLevels = ({ sessionsList, levelsList }) => {
  const title = t("profile.advanceArabi");
  const tooltipName = t("profile.arabi21");

  const getLabelLevel = (value) => {
    const level = levelsList.find((level) => level.value === value);
    return level?.code;
  };

  const getLabelLevelById = (value) => {
    const level = levelsList.find((level) => level.id === value);
    return level?.value;
  };

  const data = sessionsList.map((session) => ({
    id: new Date(session?.createdAt).getTime() / 1000,
    value: getLabelLevelById(session?.level),
  }));

  const style = {
    color: "#E52730",
    fontSize: "14px",
    fontWeight: "bold",
  };
  return (
    <LineChart
      title={title}
      getLabel={getLabelLevel}
      tooltipName={tooltipName}
      data={data}
      style={style}
    />
  );
};
export default StudentProgressLevels;
