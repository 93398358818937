import { useEffect, useState } from "react";

const useFetch = (fetcher) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const data = await fetcher();
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, []);

  return { isLoading, error, data, setData };
};

export default useFetch;
