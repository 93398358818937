import React from "react";
import { t } from "i18next";

const StudentMistakes = ({ LastSession, getMistake }) => {
  const mistakes = LastSession?.reading?.behaviors;

  let mistakesCount = mistakes?.reduce((acc, mistake) => {
    const mistakeId = mistake.behavior;
    acc[mistakeId] = (acc[mistakeId] || 0) + 1;
    return acc;
  }, {});

  if (!mistakesCount) mistakesCount = {};

  const topMistakes = Object.entries(mistakesCount)
    .map(([id, count]) => ({ id, count }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 3)
    .map(({ id }) => id);

  return (
    <React.Fragment>
      <h5 className="card-title mb-4">{t("profile.focus")}</h5>
      <div
        className="d-flex flex-wrap gap-2 fs-15"
        style={{ marginTop: "-4px" }}
      >
        {topMistakes?.length ? (
          topMistakes.map((mistake, index) => {
            const { color, title } = getMistake(mistake);
            return (
              <span
                key={index}
                className="badge"
                style={{
                  backgroundColor: color,
                  fontSize: "19px",
                  fontWeight: 400,
                  padding: "10px 30px",
                  borderRadius: "10px",
                }}
              >
                {title}
              </span>
            );
          })
        ) : (
          <div>لا يوجد اخطاء</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default StudentMistakes;
