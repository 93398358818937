const findById = (array, id) => array.find((item) => item.id === id);

export const calculateAverageLevel = (roundId, levels, sessions) => {
  const studentsInRound = sessions.filter((s) => {
    return s.round === roundId;
  });
  if (!studentsInRound.length) return null;

  const levelSum = studentsInRound.reduce(
    (acc, session) => acc + findById(levels, session.level)?.value || 0,
    0
  );

  return levelSum / studentsInRound.length;
};
