import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import user from "../../assets/images/students.png";
import { useData } from "../../context/StoreContext";

const NavHeader = () => {
  const { currentUser } = useData();

  return (
    <nav className="kutubee-nav">
      <div className="container">
        <div className="d-flex align-items-center">
          {/* Logo */}
          <Link className="kutubee-nav_logo" to="/">
            <img className="kutubee-nav_logo_img" src={logo} alt="Kutubee" />
          </Link>

          {/* Classrooms */}
          {currentUser && currentUser?.role?.id === "superadmin" ? (
            <Link className="kutubee-nav_classrooms" to="/">
              <img src={user} alt="User" />
              <span>المدارس</span>
            </Link>
          ) : (
            <Link className="kutubee-nav_classrooms" to="/">
              <img src={user} alt="User" />
              <span>صفوفي</span>
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavHeader;
