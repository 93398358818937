import { Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
const AreaChart = ({ title, getYAxisLabel, getXAxisLabel, data, style }) => {
  const seriesLength = data.length;
  const tooltipName = "المستوى";

  return (
    <Col md={5}>
      <div className="section-chart-block">
        <h3 className="section-chart-title">{title}</h3>
        <div>
          <ReactApexChart
            options={{
              grid: {
                show: true,
                padding: {
                  left: 20,
                  right: 20,
                },
              },
              fill: {
                gradient: {
                  shade: "light",
                  type: "vertical",
                  inverseColors: false,
                  colorStops: [],
                  shadeIntensity: 1,
                  opacityFrom: 0.4,
                  opacityTo: 0.7,
                  stops: [0, 90, 100],
                },
              },
              chart: {
                height: 450,
                type: "area",
                stacked: true,
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: false,
                },
                toolbar: {
                  show: false,
                  autoSelected: "zoom",
                  tools: {
                    download: false,
                    selection: false,
                    pan: false,
                    reset: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                  },
                },
              },
              title: {
                align: "right",
              },
              subtitle: {
                align: "right",
              },
              markers: {
                size: 6,
              },
              dataLabels: {
                enabled: true,
                formatter: (val, opts) => getYAxisLabel(val),
                offsetY: -10,
                style: {
                  fontSize: style.fontSize,
                },
              },
              stroke: {
                curve: "straight",
              },
              colors: [style.color],
              tooltip: {
                shared: false,
              },
              xaxis: {
                type: "category",
                labels: {
                  offsetX: 3,
                  show: true,
                  style: {
                    fontSize: style.fontSize,
                    fontWeight: style.fontWeight,
                  },
                  formatter: (value) => getXAxisLabel(value),
                },
              },
              yaxis: {
                opposite: true,
                tickAmount: seriesLength,
                showAlways: true,
                stepSize: seriesLength > 1 ? 1 : 3,
                labels: {
                  show: true,
                  style: {
                    fontSize: style.fontSize,
                    fontWeight: style.fontWeight,
                  },
                  formatter: (value) => getYAxisLabel(value),
                },
              },
            }}
            series={[
              {
                name: tooltipName,
                data: data?.map((ele) => {
                  return {
                    x: ele.id,
                    y: ele.value,
                  };
                }),
              },
            ]}
            type="area"
            height="393"
            className="apex-charts"
          />
        </div>
      </div>
    </Col>
  );
};

export default AreaChart;
