import React from "react";
import AreaChart from "../Charts/AreaChart";
import { calculateAverageLevel } from "../../data/DataHandling/sections";

const AssessmentRoundResult = ({
  sessionsList,
  levelsList,
  assessmentsRoundList,
}) => {
  const title = "مستوى التقييمات سنوياً";

  const getLevelCode = (value) => {
    const level = levelsList.find((level) => level.value === Math.round(value));
    return level?.code;
  };

  const getRoundName = (id) => {
    const round = assessmentsRoundList.find((round) => round.id === id);
    return round?.title?.ar;
  };

  const averageLevels = assessmentsRoundList
    ?.map((round) => {
      return {
        id: round.id,
        date: new Date(round.startDate).getTime() / 1000,
        value: calculateAverageLevel(round.id, levelsList, sessionsList),
      };
    })
    .filter((roundData) => {
      return roundData.value !== null;
    })
    .sort((a, b) => b.date - a.date);

  const style = {
    color: "#AEC312",
    fontSize: "14px",
    fontWeight: "bold",
  };

  return (
    <AreaChart
      title={title}
      data={averageLevels}
      style={style}
      getYAxisLabel={getLevelCode}
      getXAxisLabel={getRoundName}
    />
  );
};

export default AssessmentRoundResult;
