import {
  calculateTotalClasses,
  calculateTotalStudents,
  getTeacherSectionsLabel,
} from "../data/DataHandling/teachers";

export const mapSectionsWithClassroomData = (sections, classrooms) => {
  return sections.map((section) => {
    const classroom = classrooms.find((c) => c?.id === section?.classroom);

    return {
      ...section,
      classroom,
    };
  });
};

export const mapSectionsWithClassroomLabels = (sections, classrooms) => {
  return sections?.map((section) => {
    const classroom = classrooms?.find((classroom) => {
      return classroom?.id === section?.classroom;
    });

    return {
      value: section?.id,
      label: `${classroom?.name?.ar} - ${section?.name?.ar}`,
    };
  });
};

export const mapperTeacherData = (user, sections, labels) => {
  return {
    ...user,
    sections: getTeacherSectionsLabel(user?.sections, labels),
    data: {
      numberOfClasses: calculateTotalClasses(user?.sections, sections) || 0,
      numberOfSections: user?.sections?.length || 0,
      numberOfStudents: calculateTotalStudents(user?.sections, sections) || 0,
    },
  };
};

export const enhancedTeachers = (users, sections) =>
  users.map((user) => ({
    ...user,
    sections: getTeacherSectionsLabel(user?.sections, sections),
  }));
