import React from "react";
import LanguageDropdown from "../Components/Common/LanguageDropdown";
import ProfileDropdown from "../Components/Common/ProfileDropdown";

const Header = ({ headerClass }) => {
  return (
    <header id="page-topbar" className={headerClass}>
      <div className="layout-width">
        <div className="navbar-header">
          <div className="container">
            <div className="d-flex justify-content-end">
              <div className="d-flex align-items-center">
                <ProfileDropdown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
