class AssessmentRoundService {
  constructor(assessmentRoundRepository) {
    this.assessmentRoundRepository = assessmentRoundRepository;
  }

  async getAssessmentRoundsBySchoolId(id) {
    const assessmentRounds =
      await this.assessmentRoundRepository.getAssessmentRoundsBySchoolId(id);
    return assessmentRounds.filter((round) => round.status == "active");
  }
  async getAssessmentRoundById(id) {
    const assessmentRound =
      await this.assessmentRoundRepository.getAssessmentRoundById(id);
    return assessmentRound;
  }

  async addAssessmentRound({ schoolId, name, startDate, endDate }) {
    const params = {
      title: { ar: name },
      startDate,
      endDate,
      school: schoolId,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    return await this.assessmentRoundRepository.addAssessmentRound(params);
  }

  async addDefaultAssessmentRound() {
    const currentDate = new Date();
    const params = {
      title: {
        ar: "التقييم الأول",
      },
      startDate: currentDate,
      endDate: currentDate,
      createdAt: currentDate,
      status: "active",
    };

    return await this.assessmentRoundRepository.addAssessmentRound(params);
  }

  async deleteRound(id) {
    await this.assessmentRoundRepository.deleteRound(id);
  }
}
export default AssessmentRoundService;
