import React, { useMemo } from "react";
import { useData } from "../../context/StoreContext";
import ClassesList from "../../Components/Classes/ClassesList";
import Loading from "../../Components/Loading/Loading";
import { sectionsQuery, useSections } from "../../data/hooks/useSections";
import ErrorDisplay from "../../Components/Error/ErrorDisplay";
import { Container } from "reactstrap";

const ClassesPage = () => {
  const { currentUser } = useData();
  const sectionsIds = currentUser.sections;

  const { isSectionsLoading, sectionsError, sectionsData } = useSections(
    sectionsQuery.byIds,
    sectionsIds
  );

  const classesIds = useMemo(() => {
    return [...new Set(sectionsData?.map((section) => section?.classroom))];
  }, [sectionsData]);

  if (sectionsError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching classes"}
        errorMessage={sectionsError}
      />
    );
  }

  if (isSectionsLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <ClassesList sectionsData={sectionsData} classesIds={classesIds} />
    </Container>
  );
};

export default ClassesPage;
