import React from "react";
import ClassCard from "./ClassCard";
import { classroomsQuery, useClassrooms } from "../../data/hooks/useClassrooms";
import ErrorDisplay from "../Error/ErrorDisplay";
import Loading from "../Loading/Loading";
import { ReactComponent as BeeIcon } from "../../assets/images/svg/bee-sad.svg";

const ClassesList = ({ sectionsData, classesIds }) => {
  const { isClassroomsLoading, classroomsError, classroomsData } =
    useClassrooms(classroomsQuery.byIds, classesIds);

  if (classroomsError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching classes"}
        errorMessage={classroomsError}
      />
    );
  }

  if (isClassroomsLoading) {
    return <Loading />;
  }

  if (!classroomsData?.length) {
    return (
      <div
        className="d-flex justify-content-center align-items-center flex-column gap-4"
        style={{ height: "65vh" }}
      >
        <BeeIcon />
        <span className="fs-1">لا يوجد صفوف لعرضها</span>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: "80px" }}>
        {classroomsData.map((classroom, key) => (
          <ClassCard
            key={key}
            classroom={classroom}
            sectionsData={sectionsData}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default ClassesList;
