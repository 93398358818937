const LevelCell = ({ level }) => {
  return level ? (
    <div className="level-cell" style={{ backgroundColor: level?.color }}>
      {level?.code}
    </div>
  ) : (
    "-"
  );
};
export default LevelCell;
