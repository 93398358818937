import BackendService from "../../service/BackendService";

class AssessmentRoundRepository {
  constructor() {
    this.backendService = new BackendService("rounds");
  }

  async getAssessmentRoundsBySchoolId(id) {
    const assessmentRound = await this.backendService.getManyByFieldId(
      "school",
      id
    );

    return assessmentRound;
  }

  async getAssessmentRoundById(id) {
    const assessmentRound = await this.backendService.getOne(id);
    return assessmentRound;
  }

  async addAssessmentRound(params) {
    return await this.backendService.addOne(params);
  }

  async deleteRound(id) {
    return await this.backendService.deleteOne(id);
  }
}
export default AssessmentRoundRepository;
