import React from "react";
import { Col } from "reactstrap";

const StudentStats = ({ title, value, class: className }) => {
  return (
    <Col md>
      <div className="profile-card-block-item">
        <h3>{title}</h3>
        <h4 className={className}>{value}</h4>
      </div>
    </Col>
  );
};

export default StudentStats;
