import React from "react";

export const PaginationButton = ({ icon, onClick, disabled }) => {
  const iconWithProps = React.Children.map(icon, (child) =>
    React.cloneElement(child, {
      fill: disabled ? "#C0C0C0" : "#515151",
      width: 16,
      height: 16,
      style: { padding: 1 },
    })
  );
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{
        borderRadius: "50%",
        width: 30,
        height: 30,
        border: "1px solid #e7e7e7",
        backgroundColor: disabled ? " #f8f8f8" : "#fff",
      }}
    >
      {iconWithProps}
    </button>
  );
};

export default PaginationButton;
