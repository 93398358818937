import React from "react";
import { Col } from "reactstrap";
import { ReactComponent as MenuDots } from "../../assets/images/icons/menu-dots-vertical.svg";

import DropdownButton from "../Buttons/DropdownButton";

const InfoCard = ({ icon, color, title, value, dropdownItems }) => {
  return (
    <Col className="mb-5" md>
      <div className="info-card gap-3">
        {dropdownItems?.length > 0 && (
          <div className="round-menu-dots">
            <DropdownButton
              items={dropdownItems}
              button={<MenuDots width={18} height={18} />}
              className={"dropdown-button"}
            />
          </div>
        )}
        <div className="info-card-icon" style={{ backgroundColor: color }}>
          {icon}
        </div>
        <div>
          <div style={{ fontSize: 22 }}>{value}</div>
          <div style={{ fontSize: 18 }}>{title}</div>
        </div>
      </div>
    </Col>
  );
};

export default InfoCard;
