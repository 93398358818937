import React from "react";

//import Scss
import "./assets/scss/themes.scss";
import "./style/index.css";

//imoprt Route
import Route from "./Routes";

// Import Firebase Configuration file
import { initFirebaseService } from "./service/FirebaseAuthService";
import firebaseConfig from "./config/firebase_config";
import { ToastContainer } from "react-toastify";

// init firebase backend
initFirebaseService(firebaseConfig);

function App() {
  return (
    <React.Fragment>
      <Route />
      <ToastContainer position="top-center" rtl={true} closeButton={false} />
    </React.Fragment>
  );
}

export default App;
