import React from "react";
import { CardHeader, AccordionHeader } from "reactstrap";
import { convertTimestamp } from "../../../utils/convertTimestamp";

const AccordionSessionHeader = ({ targetId, date }) => {
  return (
    <AccordionHeader targetId={targetId}>
      <CardHeader>
        {date && (
          <div className="d-flex align-items-center">
            <p className="text-muted text-truncate mb-0 handle-date-class">
              <span
                className="fw-semibold text-dark session-item-date"
                style={{ fontSize: "17px" }}
              >
                {convertTimestamp(date)}
              </span>
            </p>
          </div>
        )}
      </CardHeader>
    </AccordionHeader>
  );
};

export default AccordionSessionHeader;
