import {
  mapSectionsWithClassroomData,
  mapSectionsWithClassroomLabels,
} from "../../../Mapper/TeacherMapper";
import ClassRoomsRepository from "../../repository/ClassroomsRepository";
import SectionsRepository from "../../repository/SectionsRepository";
import UsersRepository from "../../repository/UsersRepository";
import ClassroomsService from "../../services/ClassroomsService";
import SectionsService from "../../services/SectionsService";
import UsersService from "../../services/UsersService";
import useFetch from "../utils/useFetch";

const createRepositories = () => ({
  users: new UsersRepository(),
  classrooms: new ClassRoomsRepository(),
  sections: new SectionsRepository(),
});

const createServices = (repositories) => ({
  users: new UsersService(repositories.users),
  classrooms: new ClassroomsService(repositories.classrooms),
  sections: new SectionsService(repositories.sections),
});

const fetchData = async (schoolId, teacherId) => {
  const repositories = createRepositories();
  const services = createServices(repositories);

  const teacher = await services.users.getUserById(teacherId);
  const classroom = await services.classrooms.getClassroomsBySchoolId(schoolId);
  const sections = await fetchSections(classroom, services.sections);

  const secNotInUser = sections?.filter((section) => {
    return !teacher?.sections?.some((userSec) => userSec === section?.id);
  });

  const secInUser = sections?.filter((section) => {
    return teacher?.sections?.some((userSec) => userSec === section?.id);
  });

  const sectionsLabel = mapSectionsWithClassroomLabels(secNotInUser, classroom);
  const sectionsClasses = mapSectionsWithClassroomData(secNotInUser, classroom);
  const sectionsData = mapSectionsWithClassroomData(secInUser, classroom);

  return {
    teacher: { ...teacher, sectionsData },
    sectionsLabel,
    sectionsClasses,
  };
};

const fetchSections = async (classrooms, sectionsService) => {
  if (!classrooms?.length) return [];

  const sectionsPromises = classrooms.map((classroom) =>
    sectionsService.getSectionsByClassId(classroom.id)
  );
  const sections = await Promise.all(sectionsPromises);
  return sections.flat();
};

const useTeacher = (schoolId, teacherId) => {
  const {
    data: teacherData,
    isLoading: isTeacherLoading,
    error: teacherError,
    setData,
  } = useFetch(() => fetchData(schoolId, teacherId));
  return { isTeacherLoading, teacherError, teacherData, setData };
};

export default useTeacher;
