const Loader = ({ size = 2, color = "#fff" }) => {
  return (
    <div
      className="spinner-border"
      style={{ width: `${size}rem`, height: `${size}rem`, color: color }}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default Loader;
