import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Loader from "./Loader";
import defaultAvatar from "../../assets/images/users/user-dummy-img.jpg";
import logoutIcon from "../../assets/images/svg/logout.svg";
import settingIcon from "../../assets/images/svg/settings.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/icons/arrow-down.svg";
import { getFirebaseService } from "../../service/FirebaseAuthService";
import { useData } from "../../context/StoreContext";
import UserImage from "../Image/UserImage";
import { ToastMessage } from "../Toast/ToastMessage";
import { t } from "i18next";

const ProfileDropdown = () => {
  const { currentUser } = useData();
  const history = useHistory();
  const authService = getFirebaseService();

  const userPhoto = currentUser?.imageLink;
  const firstName = currentUser?.firstName;

  const [isDropdown, setIsDropdown] = useState(false);

  const toggleDropdown = () => setIsDropdown((prev) => !prev);

  const logout = async () => {
    try {
      await authService.logout();
      localStorage.clear();
      history.push("/login");
    } catch (error) {
      ToastMessage.error(t("sectionStudents.deleteStudentValidation"));
      console.error(error);
    }
  };

  if (!currentUser) return <Loader />;

  return (
    <Dropdown
      isOpen={isDropdown}
      toggle={toggleDropdown}
      className="header-item topbar-user"
    >
      <DropdownToggle tag="button" type="button" className="btn p-0">
        <span className="d-flex align-items-center">
          <UserImage
            src={userPhoto ? userPhoto : defaultAvatar}
            name={firstName}
            size={1.8}
          />
          <span className="text-start ms-xl-2" style={{ marginRight: "10px" }}>
            <span className="d-none d-xl-inline-block fw-medium user-name-text">
              {firstName}
            </span>
          </span>
          <ArrowDownIcon width={18} height={18} fill="#ffffff" />
        </span>
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu-end"
        style={{ boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
      >
        <h6 className="dropdown-header">
          {t("Header.welcome")} {firstName}
        </h6>
        <Link to={`/users/${currentUser.id}/edit`} className="dropdown-item">
          <span onClick={toggleDropdown}>
            <img src={settingIcon} alt="logout-icon" style={{ width: 18 }} />
            <span className="align-middle mx-2">{t("Header.settings")}</span>
          </span>
        </Link>
        <button onClick={logout} className="dropdown-item text-danger">
          <img src={logoutIcon} alt="logout-icon" style={{ width: 18 }} />
          <span className="align-middle mx-2">تسجيل خروج</span>
        </button>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
