import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Label } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { getFirebaseService } from "../../../service/FirebaseAuthService";
import BgScreenAuth from "../components/Screen";
import TextInputField from "../../../Components/Forms/TextInputField";
import RoundedButton from "../../../Components/Buttons/RoundedButton";
import Form from "../../../Components/Forms/Form";
import { RestFormSchema } from "../../../utils/validationSchema";
import { ToastMessage, Position } from "../../../Components/Toast/ToastMessage";
import "../css/auth.css";

const ResetPasswordPage = ({ t }) => {
  document.title = t("PasswordReset.pageTitle");

  const onSubmit = async (data) => {
    try {
      const authService = getFirebaseService();
      await authService.forgetPassword(data.email);
      ToastMessage.success(t("PasswordReset.msgSent"));
    } catch (err) {
      ToastMessage.error(t("PasswordReset.notExists"), Position.TOP_RIGHT);
    }
  };
  return (
    <BgScreenAuth>
      <Card className="overflow-hidden card-auth">
        <Row className="justify-content-center g-0">
          <Col lg={12}>
            <div className="p-lg-5 p-4">
              <p className="text-muted">{t("PasswordReset.description")}</p>
              <div
                className="alert alert-borderless alert-warning text-center mb-2 mx-2"
                role="alert"
              >
                {t("PasswordReset.note")}
              </div>
              <div className="p-2">
                <Form onSubmit={onSubmit} schemaType={RestFormSchema}>
                  {({ control }) => {
                    return (
                      <React.Fragment>
                        <Label className="form-label">
                          {t("PasswordReset.email")}
                        </Label>
                        <TextInputField
                          control={control}
                          name={"email"}
                          type={"text"}
                          placeholder={t("Signin.usernamePlaceholder")}
                        />
                        <RoundedButton
                          className={"mt-4"}
                          text={t("PasswordReset.send")}
                        />
                      </React.Fragment>
                    );
                  }}
                </Form>
                <ToastContainer position="top-center" rtl />
              </div>

              <div className="mt-5 text-center">
                <p className="mb-0">
                  {t("PasswordReset.wait")}{" "}
                  <Link
                    to="/login"
                    className="fw-bold text-primary text-decoration-underline"
                  >
                    {t("PasswordReset.clickhere")}
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </BgScreenAuth>
  );
};

ResetPasswordPage.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ResetPasswordPage);
