class BehaviorTypesService {
  constructor(behaviorTypesRepository) {
    this.behaviorTypesRepository = behaviorTypesRepository;
  }

  async getBehaviorTypes() {
    const behaviorTypes = await this.behaviorTypesRepository.getBehaviorTypes();
    return behaviorTypes;
  }
}
export default BehaviorTypesService;
