import { z } from "zod";
import { t } from "i18next";
import { endOfYesterday, addYears } from "date-fns";

const today = new Date();
const nextYear = addYears(today, 1);
const yesterday = endOfYesterday();

const translateMessage = (message) => t(message);

const emailSchema = (message) => {
  return z
    .string({ required_error: translateMessage(message) })
    .email({ message: translateMessage(message) });
};

const passwordSchema = (message) => {
  return z
    .string({ required_error: translateMessage(message) })
    .min(1, translateMessage(message));
};

const textSchema = (message) => {
  return z
    .string({ required_error: translateMessage(message) })
    .trim()
    .min(1, translateMessage(message));
};

const sectionsArraySchema = (message) => {
  return z
    .object({ value: z.string(), label: z.string() })
    .array()
    .min(1, translateMessage(message))
    .optional()
    .refine((value) => value !== undefined && value.length > 0, {
      message: translateMessage(message),
    });
};

const sectionObjectSchema = (message) => {
  return z
    .object({ value: z.string(), label: z.string() })
    .optional()
    .refine((value) => value !== undefined, {
      message: translateMessage(message),
    });
};

const levelObjectSchema = (message) => {
  return z
    .object({ value: z.string(), label: z.string() })
    .optional()
    .refine((value) => value !== undefined, {
      message: translateMessage(message),
    });
};

const dateSchema = () => {
  return z
    .date({
      required_error: "يرجى تحديد التاريخ",
      invalid_type_error: "هذا ليس تاريخًا!",
    })
    .min(yesterday, { message: "هذا التاريخ قديم يرحى اخيار تاريخ اخر" })
    .max(nextYear, { message: "يرجى اختيار تاريخ قريب" });
};

export const RestFormSchema = z.object({
  email: emailSchema("PasswordReset.emailValidation"),
});

export const LoginFormSchema = z.object({
  email: emailSchema("Signin.usernameValidation"),
  password: passwordSchema("Signin.passwordValidation"),
});

export const EditProfileFormSchema = z.object({
  firstName: textSchema("editProfile.validationFirstName"),
  lastName: textSchema("editProfile.validationLastName"),
  email: emailSchema("يرجى ادخال البريدالإلكتروني"),
});

export const SchoolsFormSchema = z.object({
  name: z.object({ ar: textSchema("يرجى ادخال اسم المدرسة") }),
});

export const ClassesFormSchema = z.object({
  name: z.object({ ar: textSchema("يرجى ادخال الصف") }),
});

export const SectionFormSchema = z.object({
  name: z.object({ ar: textSchema("يرجى ادخال الشعبة") }),
});

export const SectionsFormSchema = z.object({
  sections: sectionsArraySchema("يرجى اختيار الشعب"),
});

export const TeacherFormSchema = z.object({
  firstName: textSchema("يرجى ادخال الاسم الاول"),
  lastName: textSchema("يرجى ادخال الاسم الثاني"),
  email: emailSchema("يرجى ادخال البريدالإلكتروني"),
  password: passwordSchema("يرجى ادخال كلمة المرور").optional(),
  sections: sectionsArraySchema("يرجى اختيار الشعب"),
});

export const StudentFormSchema = z.object({
  firstName: textSchema("يرجى ادخال الاسم الاول"),
  lastName: textSchema("يرجى ادخال الاسم الثاني"),
  id: z.string().trim().optional().or(z.literal("")),
  sections: sectionObjectSchema("يرجى اختيار الشعبة"),
  level: levelObjectSchema("يرجى اختيار مستوى الطالب"),
});

export const RoundFormSchema = z.object({
  name: textSchema("يرجى ادخال اسم دورة التقييم"),
  startDate: dateSchema(),
  endDate: dateSchema(),
});

export const SchoolAdminFormSchema = z.object({
  firstName: textSchema("يرجى ادخال الاسم الاول"),
  lastName: textSchema("يرجى ادخال الاسم الثاني"),
  email: emailSchema("يرجى ادخال البريدالإلكتروني"),
  password: passwordSchema("يرجى ادخال كلمة المرور").optional(),
});
