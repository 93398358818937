import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const Form = ({ children, onSubmit, schemaType, defaultValues = {} }) => {
  const methods = useForm({
    resolver: zodResolver(schemaType),
    defaultValues: defaultValues,
  });

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>{children(methods)}</form>
  );
};

export default Form;
