class LevelsService {
  constructor(levelsRepository) {
    this.levelsRepository = levelsRepository;
  }

  async getLevels() {
    const levels = await this.levelsRepository.getLevels();
    return levels;
  }
}
export default LevelsService;
