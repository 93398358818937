import React from "react";
import { Modal, ModalBody } from "reactstrap";
import OutlineButton from "../Buttons/OutlineButton";

const ConfirmationPopup = ({
  open,
  close,
  title,
  subtitle,
  onCancel,
  onConfirm,
  cancelTitle = "إلغاء",
  confirmTitle = "حذف",
  cancelColor = "#B3B3B3",
  confirmColor = "#ff3333",
  confirmMessage = "هل انت متاكد من حذف ",
}) => {
  return (
    <Modal
      isOpen={open}
      toggle={close}
      centered={true}
      contentClassName="modal-rounded"
    >
      <ModalBody className="p-4 m-3">
        <div>
          <h4 className="text-center mb-3">{title}</h4>
          <h5 className="text-center mb-3 pb-2">
            {confirmMessage}
            <span style={{ color: confirmColor }}>{subtitle}</span>؟
          </h5>
          <div className="d-flex justify-content-center align-items-center gap-4 mt-4">
            <OutlineButton
              type={"button"}
              onClick={onCancel || close}
              title={cancelTitle}
              color={cancelColor}
              style={{ maxWidth: 120, color: "#000" }}
            />
            <OutlineButton
              type={"button"}
              title={confirmTitle}
              onClick={onConfirm}
              color={confirmColor}
              style={{ maxWidth: 120 }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationPopup;
