import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { ToastMessage } from "../Components/Toast/ToastMessage";
import { useData } from "../context/StoreContext";
import { ROLES } from "../utils/roles";

const AuthProtected = (props) => {
  const history = useHistory();
  const { currentUser } = useData();
  const { children, allowedRoles, location } = props;
  const { school: schoolId } = useParams();
  const role = currentUser?.role?.id;

  const isSchoolAdmin = role === ROLES.SCHOOL_ADMIN;
  const isDifferentSchool = schoolId && currentUser?.school?.id !== schoolId;

  useEffect(() => {
    if (
      (role && allowedRoles && !allowedRoles.includes(role)) ||
      (isSchoolAdmin && isDifferentSchool)
    ) {
      ToastMessage.error("غير مصرح لك بالدخول لهذه الصفحة");
      history.push("/");
    }
  }, [role, allowedRoles, schoolId]);

  if (!currentUser) {
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
  }

  return <>{children}</>;
};

const AccessRoute = ({ component: Component, allowedRoles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <AuthProtected allowedRoles={allowedRoles}>
            <Component {...props} />
          </AuthProtected>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
