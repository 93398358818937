import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import BasicTable from "../Table/BasicTable";
import PrintButton from "../Buttons/PrintButton";
import LevelCell from "../../Components/Student/LevelCell";
import { useData } from "../../context/StoreContext";
import { ROLES } from "../../utils/roles";

const StudentsTable = ({ sectionId, classId, studentsList, schoolId }) => {
  const history = useHistory();
  const { currentUser } = useData();
  const userRole = currentUser?.role;
  const tdClickHandler = (e, cell) => {
    if (cell.column.id !== "selection") {
      userRole == ROLES.TEACHER
        ? history.push(
            `/classrooms/${classId}/sections/${sectionId}/students/${cell.row.original.id}`
          )
        : history.push(
            `/schools/${schoolId}/classrooms/${classId}/sections/${sectionId}/students/${cell.row.original.id}`
          );
    }
  };

  const columns = useMemo(
    () => [
      {
        header: t("sectionStudents.colName"),
        accessorFn: (row) => `${row.firstName || ""} ${row.lastName || ""}`,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: t("sectionStudents.colLevel"),
        accessorFn: (row) => row.level.code,
        cell: (props) => <LevelCell level={props.row.original.level} />,
      },
      {
        header: "الاستيعاب",
        accessorFn: (row) => row.sessionResult.comprehensionScore,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: t("sectionStudents.colAccuracy"),
        accessorFn: (row) => row.sessionResult.accuracyScore,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: t("sectionStudents.colLastAssessment"),
        accessorFn: (row) => row.lastAssessment.date,
        cell: (props) => (
          <span className={`${props.row.original.lastAssessment.status}`}>
            {props.row.original.lastAssessment.date}
          </span>
        ),
      },
    ],
    []
  );

  return (
    <BasicTable
      columns={columns}
      data={studentsList || []}
      handleTdClick={tdClickHandler}
      divClass="container-table mt-4 mb-5"
      tableClass="basic-table row-full-width"
      messageNotFoundResult={"لا يوجد طلاب"}
      isGlobalFilter={true}
      header={<PrintButton />}
    />
  );
};
export default StudentsTable;
