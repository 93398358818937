import React from "react";
import { Link } from "react-router-dom";

const LinkButton = ({
  text,
  className,
  to,
  color = "#fff",
  bgColor = "#6ac3db",
  radius = 35,
  maxWidth = "100%",
  height = 50,
  fontSize = 20,
  fontWeight = 700,
}) => {
  const style = {
    color: color,
    backgroundColor: bgColor,
    borderRadius: radius,
    maxWidth: maxWidth,
    height: height,
    fontSize: fontSize,
    fontWeight: fontWeight,
    width: "100%",
    textAlign: "center",
  };

  return (
    <Link to={to} className={`btn ${className}`} style={style}>
      {text}
    </Link>
  );
};

export default LinkButton;
