import React from "react";

const TableButton = ({ text, icon, onClick, isLoading }) => {
  return (
    <button
      className="table-button"
      disabled={isLoading}
      style={{ backgroundColor: isLoading ? "#c2e6f0" : " #6ac3db" }}
      onClick={onClick}
    >
      <span className="d-flex justify-content-center align-items-center gap-2">
        <span className="d-none d-md-block"> {text}</span> {icon}
      </span>
    </button>
  );
};

export default TableButton;
