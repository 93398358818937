class StudentsService {
  constructor(studentsRepository) {
    this.studentsRepository = studentsRepository;
  }

  async getStudentsByRefSections(sections) {
    if (!sections) return null;

    const students = this.studentsRepository.getStudentsByRefSections(sections);
    return students;
  }

  async getStudentsBySectionId(sectionId) {
    const students = this.studentsRepository.getStudentBySectionId(sectionId);
    return students;
  }

  async getStudentsBySchoolId(schoolId) {
    const students = this.studentsRepository.getStudentsBySchoolId(schoolId);
    return students;
  }

  async getStudentById(id) {
    return await this.studentsRepository.getStudentById(id);
  }

  async verifyStudentId(id) {
    return await this.studentsRepository.verifyStudentId(id);
  }

  async addStudentParams(params) {
    const data = {
      ...params,
      createdAt: new Date(),
    };
    return await this.studentsRepository.addStudent(data);
  }

  async updateStudentParams(id, params) {
    const data = {
      ...params,
      updatedAt: new Date(),
    };
    return await this.studentsRepository.updateStudent(id, data);
  }

  async deleteStudent(id) {
    await this.studentsRepository.deleteStudent(id);
  }
}
export default StudentsService;
