import React from "react";
import { Switch, Route } from "react-router-dom";
import Layout from "../Layouts/index";
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AccessRoute } from "./AuthProtected";
import { DataProvider } from "../context/StoreContext";

const Index = () => {
  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
  const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
  return (
    <React.Fragment>
      <Switch>
        <Route path={availablePublicRoutesPaths}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                component={route.component}
                key={idx}
                exact={true}
              />
            ))}
          </Switch>
        </Route>

        <Route path={availableAuthRoutesPath}>
          <DataProvider>
            <Layout>
              <Switch>
                {authProtectedRoutes.map((route, idx) => (
                  <AccessRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                    allowedRoles={route.allowedRoles}
                  />
                ))}
              </Switch>
            </Layout>
          </DataProvider>
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default Index;
