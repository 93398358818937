import React from "react";
import { t } from "i18next";
import { Col, Row } from "reactstrap";
import StudentStats from "./StudentStats";

const StudentInfo = ({ data }) => {
  const stats = [
    {
      title: "المستوي الحالي",
      class: "colored-primary",
      value: data.studentLevel,
    },
    {
      title: "الإجراء المقترح",
      class: "colored-varient",
      value: data.recommended,
    },
    { title: t("profile.sessionsCount"), value: data.numberOfAssessments },
    { title: t("profile.lastSessionDate"), value: data.lastAssessmentDate },
  ];

  const filteredStats = stats.filter(
    (item) => item.value !== undefined && item.value !== null
  );

  return (
    <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-card-block">
      <Row className="g-4">
        <Col>
          <div className="p-2">
            <h3 className="mb-1">
              {data.studentName}
              <span className="user-name-bio">
                {data.className} | {data.sectionName}
              </span>
            </h3>
          </div>
        </Col>
      </Row>

      <Row>
        {filteredStats.map((item, index) => (
          <StudentStats
            key={index}
            title={item.title}
            class={item.class}
            value={item.value}
          />
        ))}
      </Row>
    </div>
  );
};

export default StudentInfo;
