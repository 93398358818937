import React from "react";
import CountUp from "react-countup";

const CountUpStats = ({ value, suffix }) => {
  return (
    <CountUp start={0} end={value} duration={2} decimals={1} suffix={suffix} />
  );
};

export default CountUpStats;
