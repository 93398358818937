import BackendService from "../../service/BackendService";

class ClassRoomsRepository {
  constructor() {
    this.backendService = new BackendService("classrooms");
  }

  async getClassroomById(id) {
    const classroom = await this.backendService.getOne(id);
    return classroom;
  }

  async getClassroomsByIds(ids) {
    const classrooms = await this.backendService.getMany(ids);
    return classrooms;
  }

  async _getClassroomsByRef(query) {
    const snapshot = await this.collection
      .where(query.field, query.operator, query.value)
      .get();
    const classrooms = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return classrooms;
  }

  async getClassroomsBySchoolId(id) {
    const classrooms = await this.backendService.getManyByFieldId("school", id);
    return classrooms;
  }

  async addClassroom(schoolId, name) {
    const newClassroom = {
      name: { ar: name, en: name },
      data: {
        numberOfAssessmentRounds: 0,
        numberOfSections: 0,
        numberOfStudents: 0,
      },
      school: schoolId,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    };

    const classroom = await this.backendService.addOne(newClassroom);
    return classroom;
  }

  async deleteClassroom(id) {
    await this.backendService.deleteOne(id);
  }

  async updateClassroom(id, newName) {
    const postData = {
      name: { ar: newName, en: newName },
      updatedAt: new Date().getTime(),
    };

    await this.backendService.updateOne(id, postData);
  }
}
export default ClassRoomsRepository;
