import React from "react";
import { t } from "i18next";

const PrintButton = () => {
  return (
    <button
      className="table-button"
      style={{ backgroundColor: "#6ac3db", width: 100 }}
      onClick={window.print}
    >
      {t("profile.print")}
    </button>
  );
};

export default PrintButton;
