import React from "react";
import { Col } from "reactstrap";

const SegmentedProgressBar = ({ title, value, count }) => {
  const filledSegments = Math.round(value);
  const isNotNumber = isNaN(value);

  const score = isNotNumber ? `0/${count}` : `${filledSegments}/${count}`;

  return (
    <Col md>
      <div className="block-wrapper-inner-item">
        <h4>{title}</h4>
        <div className="d-flex align-items-center" style={{ width: "100%" }}>
          {Array.from({ length: count }).map((_, index) => (
            <span
              key={index}
              className={`round-item-wrap-bg-feature-box ${
                filledSegments > index ? "colored" : ""
              }`}
            />
          ))}
          {score}
        </div>
      </div>
    </Col>
  );
};

export default SegmentedProgressBar;
