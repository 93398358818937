import React from "react";
import { useData } from "../../context/StoreContext";
import SchoolHomePage from "../Schools/Home/HomeSchool";
import { ROLES } from "../../utils/roles";
import Schools from "../Schools/Schools";
import ClassesPage from "../Classes";
import { t } from "i18next";
import useRoles from "../../data/hooks/useRoles";

const HomePage = () => {
  const { currentUser } = useData();
  const roleId = currentUser?.role;
  const { roleData } = useRoles(roleId);

  document.title = t("sectionStudents.classrooms");

  const renderComponent = () => {
    switch (roleData.role) {
      case ROLES.SUPER_ADMIN:
        return <Schools />;
      case ROLES.SCHOOL_NETWORK_ADMIN:
        return (
          <Schools
            ids={currentUser.schools}
            user={currentUser.id}
            role={roleData.role}
          />
        );
      case ROLES.SCHOOL_ADMIN:
        return <SchoolHomePage schoolId={currentUser.school} />;
      default:
        return <ClassesPage />;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">{renderComponent()}</div>
    </React.Fragment>
  );
};

export default HomePage;
