import BackendService from "../../service/BackendService";

class AssessmentTextsRepository {
  constructor() {
    this.backendService = new BackendService("texts");
  }

  async getAssessmentTexts(id) {
    const assessmentText = await this.backendService.getOne(id);
    return assessmentText;
  }
}

export default AssessmentTextsRepository;
