import BackendService from "../../service/BackendService";

class SessionsRepository {
  constructor() {
    this.backendService = new BackendService("sessions");
  }

  async getSessionsById(id) {
    const session = await this.backendService.getOne(id);
    return session;
  }

  async getSessionsByStudentId(id) {
    const sessions = await this.backendService.getManyByFieldId("student", id);
    return sessions;
  }

  async getSessionBySectionId(id) {
    const sessions = await this.backendService.getManyByFieldId("section", id);
    return sessions;
  }
}

export default SessionsRepository;
