import React, { useEffect, useState } from "react";
import { Nav, NavLink, TabContent, TabPane, Container, Row } from "reactstrap";
import TeachersPage from "../Teachers/TeachersPage";
import ClassesPage from "../Classrooms/ClassesPage";
import StudentsPage from "../Students/StudentsPage";
import BreadcrumbsBox from "../../../Components/Breadcrumb/BreadcrumbsBox";
import useSchool from "../../../data/hooks/schools/useSchool";
import { useParams, useLocation } from "react-router-dom";
import ErrorDisplay from "../../../Components/Error/ErrorDisplay";
import Loading from "../../../Components/Loading/Loading";
import InfoCard from "../../../Components/Card/InfoCard";
import { ReactComponent as UsersIcon } from "../../../assets/images/icons/users.svg";
import { ReactComponent as StudentsIcon } from "../../../assets/images/icons/students.svg";
import { ReactComponent as RoundIcon } from "../../../assets/images/icons/round.svg";
import { ReactComponent as ClassroomIcon } from "../../../assets/images/icons/classroom.svg";
import { ReactComponent as AddRoundIcon } from "../../../assets/images/icons/add-round.svg";
import AssessmentRound from "../../../Components/School/AssessmentRound";
import { useData } from "../../../context/StoreContext";
import { ROLES } from "../../../utils/roles";
import SchoolAdmin from "../Admin/SchoolAdmin";
import useRoles from "../../../data/hooks/useRoles";

const SchoolHomePage = (props) => {
  const { currentUser } = useData();
  const { school: schoolId = props.schoolId } = useParams();

  const roleId = currentUser?.role?.trim();
  const { roleData } = useRoles(roleId);
  const userRole = roleData.role;

  const { isSchoolLoading, schoolError, schoolData, setData } =
    useSchool(schoolId);

  const [activeTab, setActiveTab] = useState("1");
  const [renderedTabs, setRenderedTabs] = useState({ 1: true });
  const [addRound, setAddRound] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/teachers")) {
      toggleTab("1");
    } else if (path.includes("/classrooms")) {
      toggleTab("2");
    } else if (path.includes("/students")) {
      toggleTab("3");
    }
  }, [location]);

  const toggleTab = (tab) => {
    if (!renderedTabs[tab]) {
      setRenderedTabs({ ...renderedTabs, [tab]: true });
    }
    setActiveTab(tab);
  };

  if (schoolError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching School"}
        errorMessage={schoolError}
      />
    );
  }

  if (isSchoolLoading) {
    return <Loading />;
  }

  const breadcrumbsList = [
    ...(userRole == ROLES.SUPER_ADMIN ? [{ label: "المدارس", link: `/` }] : []),
    { label: `${schoolData?.name?.ar || ""}` },
  ];

  const roundName = schoolData?.roundData?.title?.ar;

  const cardData = [
    {
      icon: <UsersIcon width={22} height={22} fill="#FFFFFF" />,
      title: "عدد المعلمين",
      value: schoolData?.data?.numberOfTeachers,
      color: "#6ac3db",
    },
    {
      icon: <ClassroomIcon width={25} height={25} fill="#FFFFFF" />,
      title: "عدد الصفوف",
      value: schoolData?.data?.numberOfClasses,
      color: "#6ac3db",
    },
    {
      icon: <StudentsIcon width={25} height={25} fill="#FFFFFF" />,
      title: "عدد الطلاب",
      value: schoolData?.data?.numberOfStudents,
      color: "#6ac3db",
    },
    {
      icon: <RoundIcon width={40} height={40} fill="#FFFFFF" />,
      title: roundName ? "دورة التقييم الحالية" : "",
      value: roundName || "لا يوجد دورة تقييم",
      color: roundName ? "#6ac3db" : "#E2224C",

      dropdownItems: [
        {
          text: "دورة تقييم جديدة",
          icon: <AddRoundIcon width={22} height={22} />,
          onClick: () => setAddRound(true),
        },
      ],
    },
  ];

  return (
    <div className="page-content">
      <BreadcrumbsBox breadcrumbsList={breadcrumbsList} />
      <Container className="mt-5">
        <Row>
          {cardData.map((item, index) => (
            <InfoCard
              key={index}
              icon={item.icon}
              color={item.color}
              title={item.title}
              value={item.value}
              dropdownItems={item.dropdownItems}
            />
          ))}
        </Row>

        <Nav tabs className="gap-1 p-0">
          <NavLink
            className={activeTab === "1" ? "active-tab" : "tab"}
            onClick={() => toggleTab("1")}
          >
            المعلمين
          </NavLink>
          <NavLink
            className={activeTab === "2" ? "active-tab" : "tab"}
            onClick={() => toggleTab("2")}
          >
            الصفوف
          </NavLink>
          <NavLink
            className={activeTab === "3" ? "active-tab" : "tab"}
            onClick={() => toggleTab("3")}
          >
            الطلاب
          </NavLink>
          {userRole == ROLES.SUPER_ADMIN && (
            <NavLink
              className={activeTab === "4" ? "active-tab" : "tab"}
              onClick={() => toggleTab("4")}
            >
              المسؤولين
            </NavLink>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {renderedTabs["1"] && <TeachersPage schoolId={schoolId} />}
          </TabPane>
          <TabPane tabId="2">
            {renderedTabs["2"] && <ClassesPage schoolId={schoolId} />}
          </TabPane>
          <TabPane tabId="3">
            {renderedTabs["3"] && <StudentsPage schoolId={schoolId} />}
          </TabPane>
          {userRole == ROLES.SUPER_ADMIN && (
            <TabPane tabId="4">
              {renderedTabs["4"] && <SchoolAdmin schoolId={schoolId} />}
            </TabPane>
          )}
        </TabContent>

        <AssessmentRound
          open={addRound}
          setOpen={setAddRound}
          schoolId={schoolId}
          setData={setData}
        />
      </Container>
    </div>
  );
};

export default SchoolHomePage;
