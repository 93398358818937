import SectionsRepository from "../repository/SectionsRepository";
import SectionsService from "../services/SectionsService";
import useFetch from "./utils/useFetch";

const sectionsQuery = Object.freeze({
  byIds: "getSectionsByIds",
  byId: "getSectionById",
});

const queryFunctions = {
  getSectionsByIds: async (sectionsService, options) => {
    return await sectionsService.getSectionsByIds(options);
  },
  getSectionById: async (sectionsService, options) => {
    return await sectionsService.getSectionById(options);
  },
};

const fetchData = async (query, options) => {
  const sectionsRepository = new SectionsRepository();
  const sectionsService = new SectionsService(sectionsRepository);

  const queryFunction = queryFunctions[query];

  if (!queryFunction) {
    throw new Error("Invalid query");
  }

  return await queryFunction(sectionsService, options);
};

const useSections = (query, options) => {
  const {
    data: sectionsData,
    isLoading: isSectionsLoading,
    error: sectionsError,
  } = useFetch(() => fetchData(query, options));
  return { isSectionsLoading, sectionsError, sectionsData };
};

export { useSections, sectionsQuery };
