import RolesRepository from "../repository/RolesRepository";
import useFetch from "./utils/useFetch";

const fetchData = async (id) => {
  const rolesRepository = new RolesRepository();
  const role = await rolesRepository.findOne(id);
  return role;
};

const useRoles = (id) => {
  const {
    data: roleData,
    isLoading: isRoleLoading,
    error: roleError,
  } = useFetch(() => fetchData(id));

  return {
    isRoleLoading,
    roleError,
    roleData,
  };
};

export default useRoles;
