import { calculateTotals } from "../../DataHandling/schools";
import SchoolsRepository from "../../repository/SchoolsRepository";
import SchoolsService from "../../services/SchoolsService";
import useFetch from "../utils/useFetch";

const fetchData = async (ids) => {
  const schoolsRepository = new SchoolsRepository();
  const schoolsService = new SchoolsService(schoolsRepository);
  const schools = await schoolsService.getSchools(ids);
  const totals = calculateTotals(schools);

  return { schools, totals };
};

const useSchools = (ids) => {
  const {
    data: schoolsData,
    isLoading: isSchoolsLoading,
    error: schoolsError,
    setData,
  } = useFetch(() => fetchData(ids));
  return { isSchoolsLoading, schoolsError, schoolsData, setData };
};

export default useSchools;
