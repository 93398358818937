import app from "firebase/compat/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateEmail,
} from "firebase/auth";

class FirebaseAuthService {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      app.initializeApp(firebaseConfig);
    }
    this.auth = getAuth();
  }

  updateUserEmail = async (email) => {
    const user = this.auth.currentUser;
    try {
      await updateEmail(user, email);
    } catch (error) {
      throw this._handleError(error);
    }
  };

  loginUser = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      const user = userCredential.user;
      return user;
    } catch (error) {
      throw this._handleError(error);
    }
  };
  forgetPassword = async (email) => {
    try {
      const { protocol, host } = window.location;
      await sendPasswordResetEmail(this.auth, email, {
        url: `${protocol}//${host}/login`,
      });
      return true;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  logout = async () => {
    try {
      await signOut(this.auth);
      sessionStorage.removeItem("authUser");
      sessionStorage.removeItem("role");
      return true;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  _handleError(error) {
    return error.message;
  }
}

let _fireBaseAuth = null;

const initFirebaseService = (config) => {
  if (!_fireBaseAuth) {
    _fireBaseAuth = new FirebaseAuthService(config);
  }
  return _fireBaseAuth;
};

const getFirebaseService = () => {
  return _fireBaseAuth;
};

export { initFirebaseService, getFirebaseService };
