class AssessmentTextsService {
  constructor(assessmentTextsRepository) {
    this.assessmentTextsRepository = assessmentTextsRepository;
  }

  async getAssessmentTexts(id) {
    const assessmentTexts =
      await this.assessmentTextsRepository.getAssessmentTexts(id);
    return assessmentTexts;
  }
}
export default AssessmentTextsService;
