import React from "react";
import { Input, Label } from "reactstrap";

const CheckBox = ({ text }) => {
  return (
    <div>
      <Input
        className="login-checkbox"
        type="checkbox"
        value=""
        id="auth-remember-check"
      />
      <Label className="mx-2" htmlFor="auth-remember-check">
        {text}
      </Label>
    </div>
  );
};

export default CheckBox;
