import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Position = {
  TOP_LEFT: toast.POSITION.TOP_LEFT,
  TOP_RIGHT: toast.POSITION.TOP_RIGHT,
  TOP_CENTER: toast.POSITION.TOP_CENTER,
  BOTTOM_LEFT: toast.POSITION.BOTTOM_LEFT,
  BOTTOM_RIGHT: toast.POSITION.BOTTOM_RIGHT,
  BOTTOM_CENTER: toast.POSITION.BOTTOM_CENTER,
};
class ToastMessage {
  static error = (message, position = Position.TOP_CENTER) => {
    return toast.error(message, { position: position });
  };

  static success = (message, position = Position.TOP_CENTER) => {
    return toast.success(message, { position: position });
  };

  static loading = (message, position = Position.TOP_CENTER) => {
    return toast.loading(message, { position: position });
  };

  static update = (id, type, message, position = Position.TOP_CENTER) => {
    return toast.update(id, {
      render: message,
      type,
      position,
      isLoading: false,
      autoClose: 5000,
      closeOnClick: true,
    });
  };
}

export { ToastMessage, Position };
