const UserImage = ({
  src,
  className,
  size = 7,
  radius = "50%",
  name = "image",
  position = "0",
}) => {
  const style = {
    width: `${size}rem`,
    height: `${size}rem`,
    borderRadius: radius,
    objectFit: "cover",
    objectPosition: position,
    // objectPosition: "100% 0",
  };
  return (
    <div className={className}>
      <img src={src} alt={name} style={style} />
    </div>
  );
};

export default UserImage;
