import React from "react";
import { ReactComponent as EditIcon } from "../../assets/images/icons/edit.svg";

const EditButton = ({
  onClick,
  style,
  className,
  iconColor = "#2C90AC",
  iconSize = 20,
  icon,
}) => {
  const iconWithProps = React.Children.map(icon, (child) =>
    React.cloneElement(child, {
      width: iconSize,
      height: iconSize,
      fill: iconColor,
    })
  );
  return (
    <button
      onClick={onClick}
      className={className}
      style={{
        backgroundColor: `${iconColor}2F`,
        ...style,
      }}
    >
      {icon ? (
        iconWithProps
      ) : (
        <EditIcon width={iconSize} height={iconSize} fill={iconColor} />
      )}
    </button>
  );
};

export default EditButton;
