import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ breadcrumb, icon }) => {
  return (
    <li className={` ${breadcrumb.active ? "active" : ""}`}>
      {icon}
      {breadcrumb.link ? (
        <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
      ) : (
        <span>{breadcrumb.label}</span>
      )}
    </li>
  );
};

export default Breadcrumb;
