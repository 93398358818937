import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import BgScreenAuth from "../components/Screen";
import TextInputField from "../../../Components/Forms/TextInputField";
import RoundedButton from "../../../Components/Buttons/RoundedButton";
import CheckBox from "../../../Components/Forms/CheckBox";
import Form from "../../../Components/Forms/Form";
import { getFirebaseService } from "../../../service/FirebaseAuthService";
import BackendService from "../../../service/BackendService";
import { LoginFormSchema } from "../../../utils/validationSchema";
import { ToastMessage, Position } from "../../../Components/Toast/ToastMessage";
import { ReactComponent as EyeIcon } from "../../../assets/images/icons/eye.svg";
import { ReactComponent as EyeOfIcon } from "../../../assets/images/icons/eye-off.svg";
import "../css/auth.css";

const LoginPage = ({ t }) => {
  document.title = t("Signin.pageTitle");

  const auth = getFirebaseService().auth;

  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const history = useHistory();
  const backendService = new BackendService();

  useEffect(() => {
    const accessToken = backendService.getAccessToken();
    const unsubscribe = auth.onAuthStateChanged(async function (firebaseUser) {
      if (firebaseUser && accessToken != null) {
        //return history.replace("/");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleShowPassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const authService = getFirebaseService();
      await authService.loginUser(data.email, data.password);
      history.push("/");
    } catch (error) {
      ToastMessage.error(t("Signin.formValidation"), Position.TOP_RIGHT);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BgScreenAuth>
      <Card className="overflow-hidden card-auth">
        <Row className="g-0">
          <Col className="col-wrap-content-signin" lg={12}>
            <div className="form-wrapper-login">
              <div>
                <h5 className="text-primary login-title-box">اهلاً بعودتك!</h5>
              </div>
              <div className="mt-4">
                <Form onSubmit={onSubmit} schemaType={LoginFormSchema}>
                  {({ control }) => {
                    return (
                      <React.Fragment>
                        {/* Email Field */}
                        <TextInputField
                          control={control}
                          name={"email"}
                          placeholder={t("Signin.usernamePlaceholder")}
                          type={"text"}
                        />

                        {/* Password Field */}
                        <TextInputField
                          control={control}
                          name={"password"}
                          placeholder={t("Signin.passwordPlaceholder")}
                          type={passwordType}
                          leftIcon={
                            <button
                              className="btn btn-link position-absolute end-0 top-0"
                              type="button"
                              onClick={handleShowPassword}
                            >
                              {passwordType == "password" ? (
                                <EyeIcon
                                  width={22}
                                  height={22}
                                  fill="#6ac3db"
                                />
                              ) : (
                                <EyeOfIcon
                                  width={22}
                                  height={22}
                                  fill="#6ac3db"
                                />
                              )}
                            </button>
                          }
                        />
                        <div
                          className="d-flex justify-content-between"
                          style={{
                            color: "#6AC3DB",
                          }}
                        >
                          <CheckBox text={t("Signin.remember")} />

                          <div className="float-end">
                            <Link
                              to="/pass-reset"
                              className="text-muted forget-passsword-text"
                            >
                              {t("Signin.forget")}
                            </Link>
                          </div>
                        </div>

                        <RoundedButton
                          loading={loading}
                          className={"mt-4"}
                          text={t("Signin.login")}
                        />
                      </React.Fragment>
                    );
                  }}
                </Form>
                <ToastContainer position="top-center" rtl={true} />
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </BgScreenAuth>
  );
};

LoginPage.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(LoginPage);
