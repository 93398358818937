import React from "react";
import { Col, Row } from "reactstrap";
import Breadcrumb from "./Breadcrumb";
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-icon.svg";

const BreadcrumbsBox = ({ breadcrumbsList }) => {
  return (
    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
      <div className="container">
        <Row>
          <Col xs={12}>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {breadcrumbsList.map((breadcrumb, index) => (
                  <Breadcrumb
                    key={index}
                    breadcrumb={breadcrumb}
                    icon={
                      index > 0 && (
                        <span style={{ marginInline: 10 }}>
                          <ArrowLeftIcon width={8} height={8} fill="#747474" />
                        </span>
                      )
                    }
                  />
                ))}
              </ol>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BreadcrumbsBox;
