import React, { useMemo, useState } from "react";
import { ReactComponent as LinkIcon } from "../../../assets/images/icons/linked.svg";
import { ReactComponent as Unlinked } from "../../../assets/images/icons/unlinked.svg";
import ConfirmationPopup from "../../../Components/Modals/ConfirmationPopup";
import BasicTable from "../../../Components/Table/BasicTable";
import TableButton from "../../../Components/Table/TableButton";
import DeleteButton from "../../../Components/Table/DeleteButton";
import { SectionsFormSchema } from "../../../utils/validationSchema";
import CreateEditForm from "../../../Components/Forms/CreateEditForm";
import { ToastMessage } from "../../../Components/Toast/ToastMessage";
import ErrorDisplay from "../../../Components/Error/ErrorDisplay";
import Loading from "../../../Components/Loading/Loading";
import Modal from "../../../Components/Modals/Modal";
import BreadcrumbsBox from "../../../Components/Breadcrumb/BreadcrumbsBox";
import useSchool from "../../../data/hooks/schools/useSchool";
import useTeacher from "../../../data/hooks/users/useTeacher";
import { useParams, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import UsersRepository from "../../../data/repository/UsersRepository";
import UsersService from "../../../data/services/UsersService";
import { useData } from "../../../context/StoreContext";
import { ROLES } from "../../../utils/roles";

function TeacherSections() {
  const { currentUser } = useData();
  const history = useHistory();
  const { school: schoolId, teacher: teacherId } = useParams();

  const userRole = currentUser?.role?.trim();
  const initialState = { add: false, delete: false };

  const [modalState, setModalState] = useState(initialState);
  const [selectedSection, setSelectedSection] = useState(null);

  const { isSchoolLoading, schoolError, schoolData } = useSchool(schoolId);

  const { isTeacherLoading, teacherError, teacherData, setData } = useTeacher(
    schoolId,
    teacherId
  );

  const userRepository = new UsersRepository();
  const userService = new UsersService(userRepository);

  const { teacher, sectionsLabel, sectionsClasses } = teacherData ?? {};

  const addNewSection = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, add: false });
    const { id: userId, sections: currentSections, sectionsData } = teacher;
    const { sections: newSections } = data;
    const newData = { userId, currentSections, newSections };

    const newSectionsData = sectionsClasses?.filter((section) => {
      return data?.sections?.some((userSec) => userSec?.value === section?.id);
    });
    const updatedSectionsData = [...newSectionsData, ...sectionsData];

    const updatedSectionsLabel = sectionsLabel?.filter((section) => {
      return !updatedSectionsData?.some((sec) => sec?.id === section?.value);
    });
    try {
      const updatedSections = await userService.addSectionToUser(newData);
      const updatedTeacherData = {
        ...teacher,
        sectionsData: updatedSectionsData,
        sections: updatedSections,
      };

      setData((prevState) => ({
        ...prevState,
        sectionsLabel: updatedSectionsLabel,
        teacher: updatedTeacherData,
      }));

      ToastMessage.update(toastId, "success", "تم ربط شعب جديدة بنجاح");
    } catch (error) {
      console.error("Error adding new section:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء ربط الشعب");
    }
  };

  const deleteSection = async () => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, delete: false });

    try {
      const sectionId = selectedSection.id;
      const { sections, sectionsData } = teacher ?? {};

      const updatedSections = sections.filter(
        (section) => section.id !== sectionId
      );
      const updatedSectionsData = sectionsData.filter(
        (section) => section.id !== sectionId
      );

      await userService.removeSectionFromUser({
        userId: teacherId,
        sections: updatedSectionsData,
      });

      const updatedTeacherData = {
        ...teacher,
        sectionsData: updatedSectionsData,
        sections: updatedSections,
      };

      setData((prevState) => ({ ...prevState, teacher: updatedTeacherData }));

      ToastMessage.update(toastId, "success", "تم إلغاء ربط الشعبة بنجاح");
    } catch (error) {
      console.error("Error deleting school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء إلغاء ربط الشعبة");
    }
  };
  const columns = useMemo(
    () => [
      {
        header: "الصف",
        accessorFn: (row) => row?.classroom?.name?.ar || "-",
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "الشعبة",
        accessorFn: (row) => row?.name?.ar,
        cell: (props) => <span>{props.getValue()}</span> || "-",
      },
      {
        header: "عدد الطلاب",
        accessorFn: (row) => row?.data?.numberOfStudents || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد التقييمات",
        accessorFn: (row) => row?.data?.numberOfSessions || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "إلغاء الربط",
        accessorKey: "actions",
        enableSorting: false,
        cell: (props) => (
          <div className="d-flex align-items-center gap-2">
            <DeleteButton
              icon={<Unlinked />}
              className="table-action-buttons"
              onClick={() => {
                setSelectedSection(props.row.original);
                setModalState({ ...modalState, delete: true });
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  if (teacherError || schoolError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching Sections"}
        errorMessage={teacherError || schoolError}
      />
    );
  }

  if (isSchoolLoading) {
    return <Loading />;
  }

  const breadcrumbsList = [
    ...(userRole == ROLES.SUPER_ADMIN ? [{ label: "المدارس", link: `/` }] : []),
    { label: `${schoolData?.name?.ar || ""}`, link: `/schools/${schoolId}` },
    { label: "المعلمين", link: `/schools/${schoolId}/teachers` },
    { label: `${teacher?.firstName || ""} ${teacher?.lastName || ""}` },
  ];

  const tdClickHandler = (e, cell) => {
    if (cell.column.id !== "actions") {
      history.push(
        `/schools/${schoolId}/classrooms/${cell.row.original.classroom.id}/sections/${cell.row.original.id}`
      );
    }
  };

  return (
    <div className="page-content">
      <BreadcrumbsBox breadcrumbsList={breadcrumbsList} />
      <Container className="mt-5">
        <BasicTable
          columns={columns}
          data={teacher?.sectionsData || []}
          handleTdClick={tdClickHandler}
          divClass="container-table mt-4 mb-5"
          tableClass="basic-table row-full-width"
          messageNotFoundResult={"لا يوجد شعب"}
          isLoading={isTeacherLoading}
          isGlobalFilter={true}
          header={
            <TableButton
              text={"ربط شعب"}
              icon={<LinkIcon width={18} height={18} fill="#fff" />}
              onClick={() => setModalState({ ...modalState, add: true })}
            />
          }
        />

        {/* ---------- Add Section ---------- */}
        <Modal
          open={modalState.add}
          close={() => setModalState({ ...modalState, add: false })}
          title={"ربط شعب"}
        >
          <CreateEditForm
            onSubmit={addNewSection}
            schemaType={SectionsFormSchema}
            onCancelClick={() => setModalState({ ...modalState, add: false })}
            inputFields={[
              {
                name: "sections",
                label: "الشعب",
                type: "multi-select",
                options: sectionsLabel,
              },
            ]}
          />
        </Modal>

        {/* ---------- Delete Section ---------- */}
        <ConfirmationPopup
          open={modalState.delete}
          close={() => setModalState({ ...modalState, delete: false })}
          onConfirm={deleteSection}
          title={"الغاء ربط الشعبة"}
          confirmMessage="هل أنت متأكد من الغاء"
          confirmTitle="الغاء الربط"
          subtitle={selectedSection?.name?.ar}
        />
      </Container>
    </div>
  );
}

export default TeacherSections;
