import React from "react";
import { Row } from "reactstrap";
import levelIcon from "../../../assets/images/svg/rocket.svg";
import clockIcon from "../../../assets/images/svg/clock.svg";
import { t } from "i18next";
import SegmentedProgressBar from "../../ProgressBar/SegmentedProgressBar";
import LinearProgressBar from "../../ProgressBar/LinearProgressBar";

const LatestSessionStats = ({ session, textLevel }) => {
  const duration = session?.reading?.duration;
  const comprehensionScore = session?.results?.comprehensionScore;
  const accuracyScore = session?.results?.accuracyScore;
  const fluencyScore = session?.results?.fluencyScore;

  const stats = [
    { title: "مستوي النص", icon: levelIcon, value: textLevel?.code },
    {
      title: t("session.duration"),
      icon: clockIcon,
      value: `${duration} ${t("session.seconds")}`,
    },
  ];
  return (
    <React.Fragment>
      <div className="d-flex align-items-center first-item-text-popular-box-wrapper">
        {stats.map((item, index) => (
          <div key={index} className="first-item-text-popular-box">
            <img
              src={item.icon}
              alt={item.title}
              height={22}
              style={{ marginLeft: "5px" }}
            />
            <span>{item.title}</span>: <b>{item.value} </b>
          </div>
        ))}
      </div>

      <div className="wrap-boxes-first-item-features">
        <Row>
          <SegmentedProgressBar
            title={"الفهم والاستيعاب"}
            count={9}
            value={comprehensionScore}
          />

          <LinearProgressBar
            title={"الدقة"}
            color={"#EF781D"}
            value={accuracyScore}
          />

          <LinearProgressBar
            title={"الطلاقة"}
            color={"#AEC312"}
            value={fluencyScore}
          />
        </Row>
      </div>
    </React.Fragment>
  );
};

export default LatestSessionStats;
