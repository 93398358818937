const studentInputs = (state, label, levelOptions) => {
  return [
    { name: "firstName", label: "الاسم الاول", type: "text" },
    { name: "lastName", label: "الاسم الثاني", type: "text" },
    ...(state.add
      ? [
          {
            name: "id",
            label: "الرمز التعريفي للطالب",
            type: "text",
            optional: true,
          },
        ]
      : []),
    {
      name: "level",
      label: "مستوى الطالب",
      type: "select",
      options: levelOptions,
    },
    {
      name: "sections",
      label: "الصف والشعبة",
      type: "multi-select",
      options: label,
      isMulti: false,
    },
  ];
};

export default studentInputs;
