import React, { useMemo, useState } from "react";
import FirebaseClassRoomsRepository from "../../../data/repository/ClassroomsRepository";
import ClassroomsService from "../../../data/services/ClassroomsService";
import { ReactComponent as AddIcon } from "../../../assets/images/icons/plus.svg";
import ConfirmationPopup from "../../../Components/Modals/ConfirmationPopup";
import BasicTable from "../../../Components/Table/BasicTable";
import TableButton from "../../../Components/Table/TableButton";
import EditButton from "../../../Components/Table/EditButton";
import DeleteButton from "../../../Components/Table/DeleteButton";
import { ClassesFormSchema } from "../../../utils/validationSchema";
import useSchoolClassroom from "../../../data/hooks/classrooms/useSchoolClassroom";
import CreateEditForm from "../../../Components/Forms/CreateEditForm";
import DatabaseService from "../../../service/DatabaseService";
import { ToastMessage } from "../../../Components/Toast/ToastMessage";
import ErrorDisplay from "../../../Components/Error/ErrorDisplay";
import Modal from "../../../Components/Modals/Modal";
import { useParams, useHistory } from "react-router-dom";

function ClassesPage(props) {
  const history = useHistory();
  const { school: schoolId = props.schoolId } = useParams();

  const initialState = { add: false, edit: false, delete: false };
  const [modalState, setModalState] = useState(initialState);
  const [selectedClass, setSelectedClass] = useState(null);

  const { isClassroomsLoading, classroomsError, classroomsData, setData } =
    useSchoolClassroom(schoolId);

  const dbService = new DatabaseService();
  const classroomRepository = new FirebaseClassRoomsRepository(dbService.db);
  const classroomService = new ClassroomsService(classroomRepository);

  const AddNewClass = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, add: false });
    try {
      const classroom = await classroomService.addClassroom(
        schoolId,
        data?.name?.ar
      );
      setData((prev) => [classroom, ...prev]);
      ToastMessage.update(toastId, "success", "تم إضافة صف جديد بنجاح");
    } catch (error) {
      console.error("Error adding new school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء إضافة الصف");
    }
  };

  const editClass = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, edit: false });
    try {
      const classroomId = selectedClass.id;
      await classroomService.updateClassroom(classroomId, data?.name?.ar);

      const copyData = [...classroomsData];
      const index = copyData.findIndex(
        (classroom) => classroom.id === classroomId
      );

      if (index !== -1) {
        copyData[index].name.ar = data?.name?.ar;
        copyData[index].name.en = data?.name?.ar;
      } else {
        console.warn("Classroom with ID", classroomId, "not found");
      }
      setData(copyData);

      ToastMessage.update(toastId, "success", "تم تعديل الصف بنجاح");
    } catch (error) {
      console.error("Error adding new school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء تعديل الصف");
    }
  };

  const deleteClass = async () => {
    let message = "لا يمكن حذف الصف لانه يحتوي على ";
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, delete: false });
    const entities = [];
    if (selectedClass?.data?.numberOfStudents > 0) entities.push("طلاب");
    if (selectedClass?.data?.numberOfSections > 0) entities.push("شعب");

    if (entities.length > 0) {
      message += entities.join(" و ");
      ToastMessage.update(toastId, "error", message);
    } else {
      try {
        const classroomId = selectedClass.id;
        await classroomService.deleteClassroom(classroomId);
        setData((prev) =>
          prev.filter((classroom) => classroom.id !== classroomId)
        );
        ToastMessage.update(toastId, "success", "تم حذف الصف بنجاح");
      } catch (error) {
        console.error("Error deleting school:", error);
        ToastMessage.update(toastId, "error", "حدث خطأ أثناء حذف الصف");
      }
    }
  };
  const columns = useMemo(
    () => [
      {
        header: "الصف",
        accessorFn: (row) => row?.name?.ar || "-",
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد الشعب",
        accessorFn: (row) => row?.data?.numberOfSections || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد الطلاب",
        accessorFn: (row) => row?.data?.numberOfStudents || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد التقييمات",
        accessorFn: (row) => row?.data?.numberOfSessions || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "",
        accessorKey: "actions",
        enableSorting: false,
        cell: (props) => (
          <div className="d-flex align-items-center gap-2">
            <EditButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedClass(props.row.original);
                setModalState({ ...modalState, edit: true });
              }}
            />
            <DeleteButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedClass(props.row.original);
                setModalState({ ...modalState, delete: true });
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  if (classroomsError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching classroom"}
        errorMessage={classroomsError}
      />
    );
  }

  const tdClickHandler = (e, cell) => {
    if (cell.column.id !== "actions") {
      history.push(`/schools/${schoolId}/classrooms/${cell.row.original.id}`);
    }
  };

  return (
    <React.Fragment>
      <BasicTable
        columns={columns}
        data={classroomsData}
        handleTdClick={tdClickHandler}
        divClass="container-table mt-4 mb-5"
        tableClass="basic-table row-full-width"
        messageNotFoundResult={"لا يوجد صفوف"}
        isLoading={isClassroomsLoading}
        isGlobalFilter={true}
        header={
          <TableButton
            text={"اضافة صف"}
            icon={<AddIcon width={13} height={13} fill="#fff" />}
            onClick={() => setModalState({ ...modalState, add: true })}
          />
        }
      />

      {/* ---------- Add Class ---------- */}
      <Modal
        open={modalState.add}
        close={() => setModalState({ ...modalState, add: false })}
        title={"اضافة صف"}
      >
        <CreateEditForm
          onSubmit={AddNewClass}
          schemaType={ClassesFormSchema}
          onCancelClick={() => setModalState({ ...modalState, add: false })}
          inputFields={[{ name: "name.ar", label: "اسم الصف", type: "text" }]}
        />
      </Modal>

      {/* ---------- Edit Class ---------- */}
      <Modal
        open={modalState.edit}
        close={() => setModalState({ ...modalState, edit: false })}
        title={"تعديل الصف"}
      >
        <CreateEditForm
          onSubmit={editClass}
          schemaType={ClassesFormSchema}
          values={selectedClass}
          onCancelClick={() => setModalState({ ...modalState, edit: false })}
          inputFields={[{ name: "name.ar", label: "اسم الصف", type: "text" }]}
        />
      </Modal>

      {/* ---------- Delete Class ---------- */}
      <ConfirmationPopup
        open={modalState.delete}
        close={() => setModalState({ ...modalState, delete: false })}
        onConfirm={deleteClass}
        title={"حذف الصف"}
        subtitle={selectedClass?.name?.ar}
      />
    </React.Fragment>
  );
}

export default ClassesPage;
