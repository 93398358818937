class SchoolsService {
  constructor(schoolsRepository) {
    this.schoolsRepository = schoolsRepository;
  }

  async getSchools(ids) {
    const schools = await this.schoolsRepository.getSchools(ids);
    return schools;
  }

  async getSchoolById(id) {
    const school = await this.schoolsRepository.getSchoolById(id);
    return school;
  }

  async addSchool(params) {
    return await this.schoolsRepository.addSchool(params);
  }

  async updateSchool(id, postData) {
    await this.schoolsRepository.updateSchool(id, postData);
  }

  async deleteSchool(id) {
    await this.schoolsRepository.deleteSchool(id);
  }
}
export default SchoolsService;
