import React from "react";

const OutlineButton = ({ title, type, onClick, style, color }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={{
        border: `1px solid ${color}`,
        padding: "8px 30px",
        width: "100%",
        color,
        borderRadius: 40,
        backgroundColor: "transparent",
        ...style,
      }}
    >
      {title}
    </button>
  );
};

export default OutlineButton;
