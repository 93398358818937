import BackendService from "../../service/BackendService";

class LevelsRepository {
  constructor() {
    this.backendService = new BackendService("levels");
  }

  async getLevels() {
    const levels = await this.backendService.getAll();
    const sortedLevels = levels.sort((a, b) => a.value - b.value);
    return sortedLevels;
  }
}

export default LevelsRepository;
