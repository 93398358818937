const NotFoundResult = ({ message, columnsLength }) => {
  return (
    <tr>
      <td colSpan={columnsLength} className="not-found-result">
        {message || "لم يتم العثور على نتائج."}
      </td>
    </tr>
  );
};

export default NotFoundResult;
