import { Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
const BarChart = ({ title, subtitle, getLabel, data, tooltipName, style }) => {
  return (
    <Col md={7}>
      <div className="section-chart-block">
        <h3 className="section-chart-title">{title}</h3>
        <p className="section-char-description">{subtitle}</p>

        <div style={{ height: "349px" }}>
          <ReactApexChart
            options={{
              chart: {
                type: "bar",
                height: 320,
                stacked: false,
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: false,
                },
                toolbar: {
                  show: false,
                  autoSelected: "zoom",
                  tools: {
                    download: false,
                    selection: false,
                    pan: false,
                    reset: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                  },
                },
              },
              plotOptions: {
                bar: {
                  columnWidth: style.widthColumn,
                },
              },
              colors: [style.color],
              dataLabels: {
                enabled: true,
                offsetY: 3,
                style: {
                  fontSize: style.fontSize,
                },
              },
              yaxis: {
                reversed: false,
                opposite: true,
                // tickAmount: 1,

                showAlways: true,
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  rotate: 0,
                  style: {
                    fontSize: style.fontSize,
                    fontWeight: style.fontWeight,
                  },
                  formatter: (value) => Math.round(value),
                },
              },
              xaxis: {
                type: "category",
                labels: {
                  offsetX: 0,
                  show: true,
                  style: {
                    fontSize: style.fontSize,
                    fontWeight: style.fontWeight,
                  },
                  formatter: (value) => getLabel(value),
                },
                axisTicks: {
                  show: true,
                },
              },
            }}
            series={[
              {
                name: tooltipName,
                data: data.map((ele) => {
                  return {
                    x: ele.id,
                    y: ele.value,
                  };
                }),
              },
            ]}
            type="bar"
            height="350"
            className="apex-charts"
          />
        </div>
      </div>
    </Col>
  );
};

export default BarChart;
