import React, { useMemo, useState } from "react";
import { ReactComponent as AddUserIcon } from "../../../assets/images/icons/add-user.svg";
import BasicTable from "../../../Components/Table/BasicTable";
import TableButton from "../../../Components/Table/TableButton";
import Modal from "../../../Components/Modals/Modal";
import ConfirmationPopup from "../../../Components/Modals/ConfirmationPopup";
import EditButton from "../../../Components/Table/EditButton";
import DeleteButton from "../../../Components/Table/DeleteButton";
import CreateEditForm from "../../../Components/Forms/CreateEditForm";
import useSchoolStudents from "../../../data/hooks/students/useSchoolStudents";
import { useParams, useHistory } from "react-router-dom";
import { StudentFormSchema } from "../../../utils/validationSchema";
import { ToastMessage } from "../../../Components/Toast/ToastMessage";
import StudentsRepository from "../../../data/repository/StudentsRepository";
import StudentsService from "../../../data/services/StudentsService";
import { mapperStudentData } from "../../../Mapper/StudentMapper";
import LevelCell from "../../../Components/Student/LevelCell";
import ErrorDisplay from "../../../Components/Error/ErrorDisplay";
import studentInputs from "../../../Components/Inputs/StudentInputs";

const StudentsPage = (props) => {
  const history = useHistory();
  const { school: schoolId = props.schoolId } = useParams();

  const initialState = { add: false, edit: false, delete: false };
  const [modalState, setModalState] = useState(initialState);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const studentsRepository = new StudentsRepository();
  const studentsService = new StudentsService(studentsRepository);

  const { isStudentsLoading, studentsError, studentsData, setData } =
    useSchoolStudents(schoolId);
  const { students, schoolData } = studentsData ?? {};

  const addNewStudent = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, add: false });
    try {
      const params = {
        ...data,
        section: data.sections.value,
        level: data.level.value,
        levelId: data.level.value,
        school: schoolId,
      };
      const student = await studentsService.addStudentParams(params);
      const newData = mapperStudentData({ student, ...schoolData });
      setData((prev) => ({ ...prev, students: [newData, ...prev.students] }));
      ToastMessage.update(toastId, "success", "تم إضافة الطالب بنجاح");
    } catch (error) {
      console.error("Error adding new Teacher:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء إضافة الطالب");
    }
  };

  const editStudent = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, edit: false });
    try {
      const studentId = selectedStudent.id;

      const params = {
        ...data,
        school: schoolId,
        section: data.sections.value,
        level: data.level.value,
        levelId: data.level.value,
      };

      await studentsService.updateStudentParams(studentId, params);
      const student = { ...selectedStudent, ...params };
      const newData = mapperStudentData({ student, ...schoolData });
      const updatedStudents = students.map((student) =>
        student.id === studentId ? { ...student, ...newData } : student
      );
      setData((prev) => ({ ...prev, students: updatedStudents }));
      ToastMessage.update(toastId, "success", "تم تعديل الطالب بنجاح");
    } catch (error) {
      console.error("Error updating student:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء تعديل الطالب");
    }
  };

  const deleteStudent = async () => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, delete: false });
    try {
      const studentId = selectedStudent.id;
      await studentsService.deleteStudent(studentId);
      const newData = students.filter((student) => student.id !== studentId);
      setData((prev) => ({ ...prev, students: newData }));
      ToastMessage.update(toastId, "success", "تم حذف الطالب بنجاح");
    } catch (error) {
      console.error("Error deleting student:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء حذف الطالب");
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "اسم الطالب",
        accessorFn: (row) => `${row.firstName || ""} ${row.lastName || ""}`,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "الصف",
        accessorFn: (row) => row?.classroom?.name?.ar || "-",
        size: 100,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "الشعبة",
        accessorFn: (row) => row?.section?.name?.ar || "-",
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "المستوى الحالي",
        accessorFn: (row) => row?.level?.code || "-",
        cell: (props) => <LevelCell level={props.row.original.level} />,
      },
      {
        header: "عدد التقييمات",
        accessorFn: (row) => row?.numberOfAssessment || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "اخر تقييم",
        accessorFn: (row) => row?.lastAssessment || "-",
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "",
        accessorKey: "actions",
        enableSorting: false,
        cell: (props) => (
          <div className="d-flex align-items-center gap-2">
            <EditButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedStudent(props.row.original);
                setModalState({ ...modalState, edit: true });
              }}
            />
            <DeleteButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedStudent(props.row.original);
                setModalState({ ...modalState, delete: true });
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  if (studentsError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching Students"}
        errorMessage={studentsError}
      />
    );
  }

  const tdClickHandler = (e, cell) => {
    if (cell.column.id !== "actions") {
      history.push(
        `/schools/${schoolId}/classrooms/${cell.row.original.classroom.id}/sections/${cell.row.original.section.id}/students/${cell.row.original.id}`
      );
    }
  };

  return (
    <React.Fragment>
      <BasicTable
        columns={columns}
        data={students || []}
        handleTdClick={tdClickHandler}
        divClass="container-table mt-4 mb-5"
        tableClass="basic-table row-full-width"
        messageNotFoundResult={"لا يوجد طلاب"}
        isLoading={isStudentsLoading}
        isGlobalFilter={true}
        header={
          <TableButton
            text={"اضافة طالب"}
            icon={<AddUserIcon width={16} height={16} fill="#fff" />}
            onClick={() => setModalState({ ...modalState, add: true })}
          />
        }
      />

      {/* ---------- Add Student ---------- */}
      <Modal
        open={modalState.add}
        close={() => setModalState({ ...modalState, add: false })}
        title={"إضافة طالب"}
      >
        <CreateEditForm
          onSubmit={addNewStudent}
          schemaType={StudentFormSchema}
          onCancelClick={() => setModalState({ ...modalState, add: false })}
          inputFields={studentInputs(
            modalState,
            schoolData?.sectionsLabel,
            schoolData?.levelLabels
          )}
        />
      </Modal>

      {/* ---------- Edit Student ---------- */}
      <Modal
        open={modalState.edit}
        close={() => setModalState({ ...modalState, edit: false })}
        title={"تعديل بيانات الطالب"}
      >
        <CreateEditForm
          onSubmit={editStudent}
          schemaType={StudentFormSchema}
          onCancelClick={() => setModalState({ ...modalState, edit: false })}
          values={selectedStudent}
          inputFields={studentInputs(
            modalState,
            schoolData?.sectionsLabel,
            schoolData?.levelLabels
          )}
        />
      </Modal>

      {/* ---------- Delete Student ---------- */}
      <ConfirmationPopup
        open={modalState.delete}
        close={() => setModalState({ ...modalState, delete: false })}
        onConfirm={deleteStudent}
        title={"حذف الطالب"}
        subtitle={`${selectedStudent?.firstName} ${selectedStudent?.lastName}`}
      />
    </React.Fragment>
  );
};

export default StudentsPage;
