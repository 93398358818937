import React from "react";
import OutlineButton from "../Buttons/OutlineButton";
import FormBuilder from "./FormBuilder";

const CreateEditForm = ({
  onSubmit,
  inputFields,
  schemaType,
  values,
  onCancelClick,
  titleSubmit,
}) => {
  const title = values ? "تعديل" : "إضافة";
  return (
    <FormBuilder
      onSubmit={onSubmit}
      inputFields={inputFields}
      schemaType={schemaType}
      values={values}
      cancel={
        <OutlineButton
          type={"button"}
          onClick={onCancelClick}
          title={"إلغاء"}
          color="#B3B3B3"
          style={{ maxWidth: 120, color: "#000" }}
        />
      }
      submit={
        <OutlineButton
          type={"submit"}
          title={titleSubmit || title}
          color="#4FACC6"
          style={{ maxWidth: 120 }}
        />
      }
    />
  );
};

export default CreateEditForm;
