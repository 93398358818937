class UsersService {
  constructor(usersRepository) {
    this.usersRepository = usersRepository;
  }

  async getUserById(userId) {
    const users = await this.usersRepository.getUser(userId);
    return users;
  }

  async getUserByFilter(filter) {
    const user = await this.usersRepository.getUserByFilter(filter);
    return user;
  }

  async updateLastLogin(userId) {
    await this.usersRepository.updateLastLogin(userId);
  }

  async addUserParams(params) {
    const user = await this.usersRepository.addUser(params);
    return user;
  }

  async updateCurrentUser(userId, params) {
    return await this.usersRepository.updateCurrentUser(userId, params);
  }

  async updateUser(userId, params) {
    return await this.usersRepository.updateUser(userId, params);
  }

  async updateUserParams(id, params) {
    return await this.usersRepository.updateUserParams(id, params);
  }

  async addNetworkAdminSchool(userId, schools) {
    await this.usersRepository.addNetworkAdminSchool(userId, schools);
  }

  async deleteUserById(userId) {
    await this.usersRepository.deleteUser(userId);
  }

  async addSectionToUser(params) {
    return await this.usersRepository.addSectionToUser(params);
  }

  async removeSectionFromUser(params) {
    return await this.usersRepository.removeSectionFromUser(params);
  }

  async getUsersBySchoolId(schoolId, role) {
    const users = await this.usersRepository.getUsersBySchoolId(schoolId, role);
    return users;
  }
}
export default UsersService;
