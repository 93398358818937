import React from "react";
import Loader from "../Common/Loader";

const RoundedButton = ({
  loading,
  text,
  className,
  onClick,
  type = "submit",
  color = "#fff",
  bgColor = "#bde1ec",
  radius = 35,
  maxWidth = "100%",
  height = 50,
  fontSize = 17,
  fontWeight = 700,
}) => {
  const style = {
    color,
    backgroundColor: bgColor,
    borderRadius: radius,
    maxWidth,
    height,
    fontSize,
    fontWeight,
    width: "100%",
  };

  return (
    <button
      className={`btn ${className}`}
      style={style}
      type={type}
      onClick={onClick}
    >
      {loading ? <Loader color="light" size="md" /> : text}
    </button>
  );
};

export default RoundedButton;
