import React from "react";

const PreviousStatsCard = ({ label, icon, render, value, suffix }) => {
  return (
    <div className="col">
      <div className="py-4 px-3">
        <h5 className="text-muted text-uppercase fs-5">{label}</h5>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <img src={icon} alt={label} width={36} height={36} />
          </div>
          <div className="flex-grow-1 mx-2">
            <h2 className="mb-0">
              <span style={{ fontSize: "1.6rem" }}>
                {render({ value, suffix })}
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousStatsCard;
