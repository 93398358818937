import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseService } from "./FirebaseAuthService";
import app from "firebase/compat/app";

export const createFirebaseUser = async (email, password) => {
  const app = getFirebaseService().app;
  try {
    const functions = getFunctions(app);
    const createNewUser = httpsCallable(functions, "createUser-createUser");
    const user = await createNewUser({ email, password });
    return user.data;
  } catch (error) {
    return error.message;
  }
};

export const updateFirebaseUser = async ({ email, uid }) => {
  const app = getFirebaseService().app;
  try {
    const functions = getFunctions(app);
    const updateUser = httpsCallable(functions, "updateUser-updateUser");
    await updateUser({ email, uid });
  } catch (error) {
    return error.message;
  }
};

export const verifyUserEmail = async ({ email }) => {
  const app = getFirebaseService().app;
  try {
    const functions = getFunctions(app);
    const checkUserEmail = httpsCallable(
      functions,
      "checkUserEmail-checkUserEmail"
    );
    const user = await checkUserEmail({ email });
    return user.data.exists;
  } catch (error) {
    return error.message;
  }
};

export const getFirebaseServerTime = () => {
  return app.firestore.FieldValue.serverTimestamp();
};
