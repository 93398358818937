import React from "react";
import { Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReactComponent as Calendar } from "../../assets/images/icons/calender.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isValid, addYears, fromUnixTime } from "date-fns";
import dayjs from "dayjs";
import "dayjs/locale/ar";

const DatePickerField = ({ control, name, defaultValue, placeholder }) => {
  const today = new Date();
  const nextYear = addYears(today, 1);
  const defaultDate = defaultValue && dayjs(fromUnixTime(defaultValue));

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultDate}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className="mb-3">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ar">
            <div dir="rtl" className="mt-1">
              <DatePicker
                minDate={dayjs(today)}
                maxDate={dayjs(nextYear)}
                value={value}
                onChange={(value) => {
                  if (isValid(value?.$d)) onChange(value?.$d);
                }}
                slots={{
                  openPickerIcon: () => (
                    <Calendar width={24} height={24} fill="#6ac3db" />
                  ),
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    error: !!error,
                  },
                }}
                label={placeholder}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#c7c7c7", borderWidth: 1 },
                    "&:hover > fieldset": {
                      borderColor: "#c7c7c7",
                      borderWidth: 1,
                    },
                    "&.Mui-error > fieldset": {
                      borderColor: "#ff3333",
                      borderWidth: 1,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#6ac3db",
                      outline: "1px solid #6ac3db",
                    },

                    height: "40px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                  },
                  "& legend": { display: "none" },
                  "& .MuiInputLabel-shrink": {
                    opacity: 0,
                  },
                  "& .MuiInputLabel-root": {
                    color: "#DFDFDF",
                  },
                }}
              />
            </div>
          </LocalizationProvider>
          {error && <div className="invalid-error">{error.message}</div>}
        </div>
      )}
    />
  );
};

export default DatePickerField;
