import AssessmentTextsRepository from "../repository/AssessmentTextsRepository";
import AssessmentTextsService from "../services/AssessmentTextsService";
import useFetch from "./utils/useFetch";

const fetchData = async (id) => {
  const assessmentTextsRepository = new AssessmentTextsRepository();
  const assessmentTextsService = new AssessmentTextsService(
    assessmentTextsRepository
  );
  const assessmentTexts = await assessmentTextsService.getAssessmentTexts(id);
  return assessmentTexts;
};

const useAssessmentTexts = (id) => {
  const {
    data: assessmentTextsData,
    isLoading: isAssessmentTextsLoading,
    error: assessmentTextsError,
  } = useFetch(() => fetchData(id));
  return {
    isAssessmentTextsLoading,
    assessmentTextsError,
    assessmentTextsData,
  };
};

export default useAssessmentTexts;
