class SessionsService {
  constructor(sessionsRepository) {
    this.sessionsRepository = sessionsRepository;
  }

  async getSessionsById(id) {
    return await this.sessionsRepository.getSessionsById(id);
  }

  async getSessionBySectionId(id) {
    return await this.sessionsRepository.getSessionBySectionId(id);
  }

  async getSessionsByStudentId(id) {
    return await this.sessionsRepository.getSessionsByStudentId(id);
  }
}
export default SessionsService;
