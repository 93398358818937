import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
import AssessmentRoundResult from "../../Components/Section/AssessmentRoundResult";
import StudentsLevels from "../../Components/Section/StudentsLevels";
import StudentsTable from "../../Components/Section/StudentsTable";
import useLevels from "../../data/hooks/useLevels";
import useSchool from "../../data/hooks/schools/useSchool";
import { studentsQuery, useStudents } from "../../data/hooks/useStudents";
import { sessionsQuery, useSessions } from "../../data/hooks/useSessions";
import { sectionsQuery, useSections } from "../../data/hooks/useSections";
import { classroomsQuery, useClassrooms } from "../../data/hooks/useClassrooms";
import useAssessmentRound from "../../data/hooks/useAssessmentRound";
import ErrorDisplay from "../../Components/Error/ErrorDisplay";
import Loading from "../../Components/Loading/Loading";
import BreadcrumbsBox from "../../Components/Breadcrumb/BreadcrumbsBox";
import { convertStudentsData } from "../../Mapper/StudentMapper";
import { useData } from "../../context/StoreContext";
import { getSectionBreadcrumbs } from "../../Components/Breadcrumb/getBreadcrumbs";

const SectionPage = () => {
  const params = useParams();
  const { currentUser } = useData();
  const userRole = currentUser?.role;
  const schoolId = currentUser?.school || params.school;

  const sectionId = params.section;
  const classId = params.classroom;

  const { isSchoolLoading, schoolError, schoolData } = useSchool(schoolId);

  const { isLevelsLoading, levelsError, levelsData } = useLevels();

  const { isStudentsLoading, studentsError, studentsData } = useStudents(
    studentsQuery.bySectionId,
    sectionId
  );

  const { isSectionsLoading, sectionsError, sectionsData } = useSections(
    sectionsQuery.byId,
    sectionId
  );

  const { isSessionsLoading, sessionsError, sessionsData } = useSessions(
    sessionsQuery.bySectionId,
    sectionId
  );

  const { isClassroomsLoading, classroomsError, classroomsData } =
    useClassrooms(classroomsQuery.byId, classId);

  const {
    isAssessmentRoundLoading,
    AssessmentRoundError,
    assessmentRoundData,
  } = useAssessmentRound(schoolId);

  const isError =
    schoolError ||
    levelsError ||
    studentsError ||
    sectionsError ||
    sessionsError ||
    classroomsError ||
    AssessmentRoundError;

  const isLoading =
    isSchoolLoading ||
    isLevelsLoading ||
    isStudentsLoading ||
    isSectionsLoading ||
    isSessionsLoading ||
    isClassroomsLoading ||
    isAssessmentRoundLoading;

  if (isError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching sections"}
        errorMessage={isError}
      />
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  const className = classroomsData?.name?.ar;
  const sectionName = sectionsData?.name?.ar;
  const schoolName = schoolData?.name?.ar;

  const breadcrumbsList = getSectionBreadcrumbs(
    userRole,
    schoolName,
    schoolId,
    className,
    sectionName,
    classId
  );

  document.title = sectionName || "الشعبة";

  const studentTableData = convertStudentsData(
    studentsData,
    sessionsData,
    levelsData,
    assessmentRoundData
  );

  return (
    <div className="page-content">
      <BreadcrumbsBox breadcrumbsList={breadcrumbsList} />

      <Container className="mt-5">
        {!!studentsData?.length && (
          <Row className="section-charts">
            <StudentsLevels
              studentsList={studentsData}
              levelsList={levelsData}
            />

            <AssessmentRoundResult
              sessionsList={sessionsData}
              levelsList={levelsData}
              assessmentsRoundList={assessmentRoundData}
            />
          </Row>
        )}

        <StudentsTable
          schoolId={schoolId}
          sectionId={sectionId}
          classId={classId}
          studentsList={studentTableData}
        />
      </Container>
    </div>
  );
};

export default SectionPage;
