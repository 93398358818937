import React from "react";
import * as ReactStrap from "reactstrap";

const Modal = ({ open, close, title, children }) => {
  return (
    <ReactStrap.Modal
      isOpen={open}
      toggle={close}
      centered={true}
      contentClassName="modal-rounded"
    >
      <ReactStrap.ModalBody className="p-4 m-3">
        <div>
          <h4 className="text-center mb-3">{title}</h4>
          {children}
        </div>
      </ReactStrap.ModalBody>
    </ReactStrap.Modal>
  );
};

export default Modal;
