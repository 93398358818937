import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { convertTimestamp } from "../../utils/convertTimestamp";
import SessionsDetails from "../../Components/Student/SessionsDetails";
import { studentsQuery, useStudents } from "../../data/hooks/useStudents";
import { sectionsQuery, useSections } from "../../data/hooks/useSections";
import { classroomsQuery, useClassrooms } from "../../data/hooks/useClassrooms";
import { sessionsQuery, useSessions } from "../../data/hooks/useSessions";
import useLevels from "../../data/hooks/useLevels";
import useBehaviorTypes from "../../data/hooks/useBehaviorTypes";
import ErrorDisplay from "../../Components/Error/ErrorDisplay";
import Loading from "../../Components/Loading/Loading";
import BreadcrumbsBox from "../../Components/Breadcrumb/BreadcrumbsBox";
import StudentInfo from "../../Components/Student/StudentInfo";
import { t } from "i18next";
import { getStudentBreadcrumbs } from "../../Components/Breadcrumb/getBreadcrumbs";
import useSchool from "../../data/hooks/schools/useSchool";
import { useData } from "../../context/StoreContext";

const StudentPage = () => {
  const params = useParams();
  const { currentUser } = useData();

  const studentId = params.student;
  const sectionId = params.section;
  const classId = params.classroom;

  const userRole = currentUser?.role;
  const schoolId = currentUser?.school || params.school;

  const { isSchoolLoading, schoolError, schoolData } = useSchool(schoolId);

  const { isLevelsLoading, levelsError, levelsData } = useLevels();

  const { isStudentsLoading, studentsError, studentsData } = useStudents(
    studentsQuery.byId,
    studentId
  );

  const { isSectionsLoading, sectionsError, sectionsData } = useSections(
    sectionsQuery.byId,
    sectionId
  );

  const { isSessionsLoading, sessionsError, sessionsData } = useSessions(
    sessionsQuery.byStudentId,
    studentId
  );

  const { isClassroomsLoading, classroomsError, classroomsData } =
    useClassrooms(classroomsQuery.byId, classId);

  const { isBehaviorTypesLoading, behaviorTypesError, behaviorTypesData } =
    useBehaviorTypes();

  const getLevelName = (levelId) => {
    const level = levelsData.find((level) => level.id === levelId);
    return level?.code;
  };

  document.title = t("profile.pageTitle") || "الطالب";

  const isError =
    schoolError ||
    levelsError ||
    studentsError ||
    sectionsError ||
    sessionsError ||
    behaviorTypesError ||
    classroomsError;

  const isLoading =
    isSchoolLoading ||
    isLevelsLoading ||
    isStudentsLoading ||
    isSectionsLoading ||
    isSessionsLoading ||
    isBehaviorTypesLoading ||
    isClassroomsLoading;

  if (isError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching sections"}
        errorMessage={isError}
      />
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  const schoolName = schoolData?.name?.ar;
  const className = classroomsData?.name?.ar;
  const sectionName = sectionsData?.name?.ar;
  const studentName = `${studentsData?.firstName || ""}
   ${studentsData?.lastName || ""}`;

  const breadcrumbsList = getStudentBreadcrumbs(
    userRole,
    schoolName,
    schoolId,
    className,
    sectionName,
    classId,
    sectionId,
    studentName
  );

  const studentInfoData = {
    className,
    sectionName,
    studentName,
    studentLevel: getLevelName(studentsData?.level?.id),
    recommended: sessionsData[0]?.recommendation,
    numberOfAssessments: sessionsData?.length,
    lastAssessmentDate: convertTimestamp(sessionsData[0]?.createdAt),
  };

  return (
    <React.Fragment>
      <div className={`page-content`}>
        <BreadcrumbsBox breadcrumbsList={breadcrumbsList} />

        <Container className="mt-5">
          <StudentInfo data={studentInfoData} />

          <SessionsDetails
            sessionsList={sessionsData}
            levelsList={levelsData}
            behaviorTypesList={behaviorTypesData}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StudentPage;
