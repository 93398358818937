import UsersRepository from "../../repository/UsersRepository";
import UsersService from "../../services/UsersService";
import useFetch from "../utils/useFetch";

const fetchData = async (schoolId) => {
  const usersRepository = new UsersRepository();
  const usersService = new UsersService(usersRepository);
  const users = await usersService.getUsersBySchoolId(schoolId, "schooladmin");
  return users;
};

const useSchoolAdmin = (schoolId) => {
  const {
    data: schoolAdminData,
    isLoading: isSchoolAdminLoading,
    error: schoolAdminError,
    setData,
  } = useFetch(() => fetchData(schoolId));
  return { isSchoolAdminLoading, schoolAdminError, schoolAdminData, setData };
};

export default useSchoolAdmin;
