import React from "react";
import { CardBody, Col, Row } from "reactstrap";
import { AccordionBody } from "reactstrap";
import SessionText from "../SessionText/SessionText";
import StudentAudioRecording from "../AudioRecording/StudentAudioRecording";
import TeacherNotes from "../Notes/TeacherNotes";
import { t } from "i18next";

const AccordionSessionBody = ({
  session,
  miscueList,
  keyItem,
  assessmentText,
  children,
}) => {
  const studentAudio = session?.reading?.recording;
  const accuracyNotes = session?.reading?.accuracyNotes;
  const comprehensionNotes = session?.comprehension?.comprehensionNotes;

  return (
    <AccordionBody accordionId={keyItem}>
      <CardBody>
        {/* session stats */}
        {children}
        <div className="assessment-body-content">
          <Row>
            <Col md={8}>
              <SessionText
                session={session}
                miscueList={miscueList}
                sessionAssessment={assessmentText}
              />
            </Col>
            <Col md={4}>
              <StudentAudioRecording src={studentAudio} title={"التسجيل"} />

              <TeacherNotes
                title={t("session.notes")}
                accuracy={accuracyNotes}
                comprehension={comprehensionNotes}
              />
            </Col>
          </Row>
        </div>
      </CardBody>
    </AccordionBody>
  );
};

export default AccordionSessionBody;
