import RolesRepository from "../repository/RolesRepository";
import useFetch from "./utils/useFetch";

const fetchData = async (role) => {
  const rolesRepository = new RolesRepository();
  const roles = await rolesRepository.findByRole(role);
  return roles[0]["id"];
};

const useRolesRole = (role) => {
  const {
    data: rolesRoleData,
    isLoading: isRolesRoleLoading,
    error: rolesRoleError,
  } = useFetch(() => fetchData(role));

  return {
    isRolesRoleLoading,
    rolesRoleError,
    rolesRoleData,
  };
};

export default useRolesRole;
