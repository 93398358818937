import BackendService from "../../service/BackendService";

class BehaviorTypesRepository {
  constructor() {
    this.backendService = new BackendService("behaviors");
  }

  async getBehaviorTypes() {
    const behaviorTypes = await this.backendService.getAll();
    return behaviorTypes;
  }
}

export default BehaviorTypesRepository;
