import StudentsRepository from "../repository/StudentsRepository";
import StudentsService from "../services/StudentsService";
import useFetch from "./utils/useFetch";

const studentsQuery = Object.freeze({
  byRefSections: "getStudentsByRefSections",
  byId: "getStudentById",
  bySectionId: "getStudentsBySectionId",
});

const queryFunctions = {
  getStudentsByRefSections: async (studentsService, options) => {
    return await studentsService.getStudentsByRefSections(options);
  },
  getStudentById: async (studentsService, options) => {
    return await studentsService.getStudentById(options);
  },
  getStudentsBySectionId: async (studentsService, options) => {
    return await studentsService.getStudentsBySectionId(options);
  },
};

const fetchData = async (query, options) => {
  const studentsRepository = new StudentsRepository();
  const studentsService = new StudentsService(studentsRepository);

  const queryFunction = queryFunctions[query];

  if (!queryFunction) {
    throw new Error("Invalid query");
  }

  return await queryFunction(studentsService, options);
};

const useStudents = (query, options) => {
  const {
    isLoading: isStudentsLoading,
    error: studentsError,
    data: studentsData,
  } = useFetch(() => fetchData(query, options));
  return { isStudentsLoading, studentsError, studentsData };
};

export { useStudents, studentsQuery };
