import React from "react";
import { Container } from "reactstrap";
import image404 from "../../../assets/images/svg/404.svg";
import notFound from "../../../assets/images/svg/not-found.svg";
import LinkButton from "../../../Components/Buttons/LinkButton";
import ParticlesAuth from "../../../Components/Auth/ParticlesAuth";
import { t } from "i18next";

const Basic404 = () => {
  document.title = t("error.pageTitle");
  return (
    <div
      className="auth-page-wrapper d-flex justify-content-center align-items-center"
      style={{ height: "80vh" }}
    >
      <div className="auth-page-content">
        <Container>
          <div className="text-center pt-4">
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-1 gap-md-5">
              <div>
                <img
                  src={image404}
                  alt="bee404"
                  style={{ width: "80%", maxWidth: 250 }}
                />
                <h3 className="text-uppercase fs-2">{t("error.msg")}</h3>
                <p className="text-muted mb-4 fs-5">{t("error.notfound")}</p>
              </div>
              <img
                src={notFound}
                alt="bee404"
                style={{ width: "70%", maxWidth: 230 }}
              />
            </div>

            <LinkButton
              to={"/"}
              className={"mt-5"}
              text={t("error.back")}
              maxWidth="250px"
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Basic404;
