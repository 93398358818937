import app from "firebase/compat/app";
import "firebase/compat/firestore";

class DatabaseService {
  constructor() {
    this.db = app.firestore();
  }
}

export default DatabaseService;
