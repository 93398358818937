import SessionsRepository from "../repository/SessionsRepository";
import SessionsService from "../services/SessionsService";
import useFetch from "./utils/useFetch";

const sessionsQuery = Object.freeze({
  bySectionId: "getSessionBySectionId",
  byStudentId: "getSessionsByStudentId",
});

const queryFunctions = {
  getSessionBySectionId: async (sessionsService, options) => {
    return await sessionsService.getSessionBySectionId(options);
  },
  getSessionsByStudentId: async (sessionsService, options) => {
    return await sessionsService.getSessionsByStudentId(options);
  },
};

const fetchData = async (query, options) => {
  const sessionsRepository = new SessionsRepository();
  const sessionsService = new SessionsService(sessionsRepository);

  const queryFunction = queryFunctions[query];

  if (!queryFunction) {
    throw new Error("Invalid query");
  }

  return await queryFunction(sessionsService, options);
};

const useSessions = (query, options) => {
  const {
    data: sessionsData,
    isLoading: isSessionsLoading,
    error: sessionsError,
  } = useFetch(() => fetchData(query, options));
  return { isSessionsLoading, sessionsError, sessionsData };
};

export { useSessions, sessionsQuery };
