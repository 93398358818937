import React from "react";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye.svg";
import { ReactComponent as EyeOfIcon } from "../../assets/images/icons/eye-off.svg";

const ShowPasswordButton = ({ type, setType }) => {
  const handleShowPassword = () => {
    setType((prevType) => (prevType === "password" ? "text" : "password"));
  };
  return (
    <button
      className="btn btn-link show-password"
      type="button"
      onClick={handleShowPassword}
    >
      {type == "password" ? (
        <EyeIcon width={22} height={22} fill="#6ac3db" />
      ) : (
        <EyeOfIcon width={22} height={22} fill="#6ac3db" />
      )}
    </button>
  );
};

export default ShowPasswordButton;
