export const calculateTotals = (schoolsArray) => {
  const totals = schoolsArray.reduce(
    (totals, school) => {
      totals.numberOfTeachers += school.data.numberOfTeachers;
      totals.numberOfStudents += school.data.numberOfStudents;
      totals.numberOfClasses += school.data.numberOfClasses;
      return totals;
    },
    { numberOfTeachers: 0, numberOfStudents: 0, numberOfClasses: 0 }
  );

  totals.numberOfSchools = schoolsArray.length;
  return totals;
};
