const pad = (num) => num.toString().padStart(2, "0");

export const convertTimestamp = (time) => {
  const date = new Date(time);
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  if (day && month && year) {
    return `${day}/${month}/${year}`;
  } else {
    return " - / - / - ";
  }
};
