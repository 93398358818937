import ClassRoomsRepository from "../../repository/ClassroomsRepository";
import ClassroomsService from "../../services/ClassroomsService";
import useFetch from "../utils/useFetch";

const fetchData = async (id) => {
  const classroomsRepository = new ClassRoomsRepository();
  const classroomsService = new ClassroomsService(classroomsRepository);
  const classrooms = await classroomsService.getClassroomsBySchoolId(id);

  return classrooms;
};

const useSchoolClassroom = (id) => {
  const {
    data: classroomsData,
    isLoading: isClassroomsLoading,
    error: classroomsError,
    setData,
  } = useFetch(() => fetchData(id));
  return { isClassroomsLoading, classroomsError, classroomsData, setData };
};

export default useSchoolClassroom;
