const ARABIC_DIACRITICS = String.fromCharCode(
  1617,
  124,
  1614,
  124,
  1611,
  124,
  1615,
  124,
  1612,
  124,
  1616,
  124,
  1613,
  124,
  1618
);

const removeArabicDiacritics = (str) => {
  return str.replace(new RegExp(`${ARABIC_DIACRITICS}`, "g"), "");
};

export const generateSlug = (str) => {
  return removeArabicDiacritics(str)
    .trim()
    .toLowerCase()
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};
