import React, { useMemo, useState } from "react";
import BasicTable from "../../Components/Table/BasicTable";
import { ReactComponent as AddIcon } from "../../assets/images/icons/plus.svg";
import TableButton from "../../Components/Table/TableButton";
import EditButton from "../../Components/Table/EditButton";
import DeleteButton from "../../Components/Table/DeleteButton";
import useSchools from "../../data/hooks/schools/useSchools";
import { useHistory, useParams } from "react-router-dom";
import ConfirmationPopup from "../../Components/Modals/ConfirmationPopup";
import Modal from "../../Components/Modals/Modal";
import CreateEditForm from "../../Components/Forms/CreateEditForm";
import SchoolsRepository from "../../data/repository/SchoolsRepository";
import SchoolsService from "../../data/services/SchoolsService";
import UsersRepository from "../../data/repository/UsersRepository";
import UsersService from "../../data/services/UsersService";
import AssessmentRoundRepository from "../../data/repository/AssessmentRoundRepository";
import AssessmentRoundService from "../../data/services/AssessmentRoundService";
import { SchoolsFormSchema } from "../../utils/validationSchema";
import { ToastMessage } from "../../Components/Toast/ToastMessage";
import ErrorDisplay from "../../Components/Error/ErrorDisplay";
import InfoCard from "../../Components/Card/InfoCard";
import { ROLES } from "../../utils/roles";
import { ReactComponent as UsersIcon } from "../../assets/images/icons/users.svg";
import { ReactComponent as StudentsIcon } from "../../assets/images/icons/students.svg";
import { ReactComponent as SchoolIcon } from "../../assets/images/icons/school.svg";
import { ReactComponent as ClassroomIcon } from "../../assets/images/icons/classroom.svg";
import { Container, Row } from "reactstrap";

const Schools = (props) => {
  const {
    ids: ids = props.ids,
    user: user = props.user,
    role: role = props.role,
  } = useParams();
  const initialState = { add: false, edit: false, delete: false };
  const [modalState, setModalState] = useState(initialState);
  const [selectedSchool, setSelectedSchool] = useState(null);

  const { isSchoolsLoading, schoolsError, schoolsData, setData } =
    useSchools(ids);
  const history = useHistory();

  const schoolsRepository = new SchoolsRepository();
  const userRepository = new UsersRepository();
  const roundRepository = new AssessmentRoundRepository();
  const roundService = new AssessmentRoundService(roundRepository);
  const schoolsService = new SchoolsService(schoolsRepository);
  const userService = new UsersService(userRepository);

  const addNewSchool = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, add: false });
    try {
      const assessmentRound = await roundService.addDefaultAssessmentRound();
      const currentDate = new Date();
      const schoolName = data?.name?.ar;
      const schoolData = {
        createdAt: currentDate,
        updatedAt: currentDate,
        name: { ar: schoolName, en: schoolName },
        round: assessmentRound.id,
      };
      const school = await schoolsService.addSchool(schoolData);
      setData((prev) => ({
        ...prev,
        schools: [school, ...prev.schools],
      }));
      if (role == ROLES.SCHOOL_NETWORK_ADMIN) {
        const schools = ids.concat(school.id);
        userService.addNetworkAdminSchool(user, schools);
      }
      ToastMessage.update(toastId, "success", "تم إضافة مدرسة جديدة بنجاح");
    } catch (error) {
      console.error("Error adding new school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء إضافة المدرسة");
    }
  };

  const editSchool = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, edit: false });
    try {
      const schoolId = selectedSchool.id;
      const schoolName = data?.name?.ar;
      const postData = {
        name: { ar: schoolName, en: schoolName },
        updatedAt: new Date(),
      };
      await schoolsService.updateSchool(schoolId, postData);

      const copyData = [...(schoolsData?.schools || [])];
      const index = copyData.findIndex((school) => school.id === schoolId);

      if (index !== -1) {
        copyData[index].name.ar = data?.name?.ar;
        copyData[index].name.en = data?.name?.ar;
      } else {
        console.warn("School with ID", selectedSchool.id, "not found");
      }
      setData((prev) => ({ ...prev, schools: copyData }));
      ToastMessage.update(toastId, "success", "تم تعديل اسم المدرسة بنجاح");
    } catch (error) {
      console.error("Error adding new school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء تعديل المدرسة");
    }
  };

  const deleteSchool = async () => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, delete: false });

    try {
      const schoolId = selectedSchool.id;
      const round = selectedSchool.round;
      await roundService.deleteRound(round);
      await schoolsService.deleteSchool(schoolId);

      if (role == ROLES.SCHOOL_NETWORK_ADMIN) {
        const schools = ids.filter((e) => e !== schoolId);
        await userService.updateUserParams(user, { schools });
      }
      setData((prev) => ({
        ...prev,
        schools: prev.schools.filter((school) => school.id !== schoolId),
      }));
      ToastMessage.update(toastId, "success", "تم حذف المدرسة بنجاح");
    } catch (error) {
      console.error("Error deleting school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء حذف المدرسة");
    }
  };
  const columns = useMemo(
    () => [
      {
        header: "اسم المدرسة",
        accessorFn: (row) => row?.name?.ar || "-",
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد المعلمين",
        accessorFn: (row) => row?.data?.numberOfTeachers || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد الصفوف",
        accessorFn: (row) => row?.data?.numberOfClasses || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد الطلاب",
        accessorFn: (row) => row?.data?.numberOfStudents || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "",
        accessorKey: "actions",
        enableSorting: false,
        cell: (props) => (
          <div className="d-flex align-items-center gap-2">
            <EditButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedSchool(props.row.original);
                setModalState({ ...modalState, edit: true });
              }}
            />
            <DeleteButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedSchool(props.row.original);
                setModalState({ ...modalState, delete: true });
              }}
            />
          </div>
        ),
      },
    ],
    []
  );
  const tdClickHandler = (e, cell) => {
    if (cell.column.id !== "actions") {
      history.push(`/schools/${cell.row.original.id}`);
    }
  };

  if (schoolsError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching Schools"}
        errorMessage={schoolsError}
      />
    );
  }
  const cardData = [
    {
      icon: <SchoolIcon width={40} height={40} fill="#FFFFFF" />,
      title: "عدد المدارس",
      value: schoolsData?.totals?.numberOfSchools,
      color: "#6ac3db",
    },
    {
      icon: <UsersIcon width={22} height={22} fill="#FFFFFF" />,
      title: "عدد المعلمين",
      value: schoolsData?.totals?.numberOfTeachers,
      color: "#6ac3db",
    },
    {
      icon: <ClassroomIcon width={25} height={25} fill="#FFFFFF" />,
      title: "عدد الصفوف",
      value: schoolsData?.totals?.numberOfClasses,
      color: "#6ac3db",
    },
    {
      icon: <StudentsIcon width={25} height={25} fill="#FFFFFF" />,
      title: "عدد الطلاب",
      value: schoolsData?.totals?.numberOfStudents,
      color: "#6ac3db",
    },
  ];
  return (
    <Container className="my-5 pt-3">
      <Row>
        {cardData.map((item, index) => (
          <InfoCard
            key={index}
            icon={item.icon}
            color={item.color}
            title={item.title}
            value={item.value}
            dropdownItems={item.dropdownItems}
          />
        ))}
      </Row>
      <BasicTable
        columns={columns}
        data={schoolsData?.schools || []}
        handleTdClick={tdClickHandler}
        divClass="container-table"
        tableClass="basic-table row-full-width"
        messageNotFoundResult={"لا يوجد مدارس"}
        isGlobalFilter={true}
        isLoading={isSchoolsLoading}
        header={
          <TableButton
            text={"اضافة مدرسة"}
            icon={<AddIcon width={13} height={13} fill="#fff" />}
            onClick={() => setModalState({ ...modalState, add: true })}
          />
        }
      />
      <Modal
        open={modalState.add}
        close={() => setModalState({ ...modalState, add: false })}
        title={"اضافة مدرسة"}
      >
        <CreateEditForm
          onSubmit={addNewSchool}
          schemaType={SchoolsFormSchema}
          onCancelClick={() => setModalState({ ...modalState, add: false })}
          inputFields={[
            { name: "name.ar", label: " اسم المدرسة", type: "text" },
          ]}
        />
      </Modal>

      <Modal
        open={modalState.edit}
        close={() => setModalState({ ...modalState, edit: false })}
        title={"تعديل مدرسة"}
      >
        <CreateEditForm
          onSubmit={editSchool}
          schemaType={SchoolsFormSchema}
          onCancelClick={() => setModalState({ ...modalState, edit: false })}
          values={selectedSchool}
          inputFields={[
            { name: "name.ar", label: " اسم المدرسة", type: "text" },
          ]}
        />
      </Modal>

      <ConfirmationPopup
        open={modalState.delete}
        close={() => setModalState({ ...modalState, delete: false })}
        onConfirm={deleteSchool}
        title={"حذف المدرسة"}
        subtitle={selectedSchool?.name?.ar}
      />
    </Container>
  );
};

export default Schools;
