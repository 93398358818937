class SectionsService {
  constructor(sectionsRepository) {
    this.sectionsRepository = sectionsRepository;
  }

  async getSectionsByIds(ids) {
    const section = this.sectionsRepository.getManySectionsByIds(ids);
    return section;
  }

  async getSectionById(id) {
    const section = this.sectionsRepository.getSectionById(id);
    return section;
  }

  async getSectionsByClassId(classId) {
    const sections = this.sectionsRepository.getSectionsByClassId(classId);
    return sections;
  }

  async addSectionParams(params) {
    const section = await this.sectionsRepository.addSection(params);
    return section;
  }

  async updateSectionParams(params) {
    await this.sectionsRepository.updateSection(params);
  }

  async deleteSection(id) {
    await this.sectionsRepository.deleteSection(id);
  }
}
export default SectionsService;
