import AssessmentRoundRepository from "../repository/AssessmentRoundRepository";
import AssessmentRoundService from "../services/AssessmentRoundService";
import useFetch from "./utils/useFetch";

const fetchAssessmentRoundData = async (id) => {
  const assessmentRoundRepository = new AssessmentRoundRepository();
  const assessmentRoundService = new AssessmentRoundService(
    assessmentRoundRepository
  );
  const assessmentRound =
    await assessmentRoundService.getAssessmentRoundsBySchoolId(id);
  return assessmentRound;
};

const useAssessmentRound = (id) => {
  const {
    data: assessmentRoundData,
    isLoading: isAssessmentRoundLoading,
    error: AssessmentRoundError,
  } = useFetch(() => fetchAssessmentRoundData(id));
  return {
    isAssessmentRoundLoading,
    AssessmentRoundError,
    assessmentRoundData,
  };
};

export default useAssessmentRound;
