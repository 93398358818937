import React from "react";
import { Controller } from "react-hook-form";
import { Input } from "reactstrap";

const TextInputField = ({
  control,
  name,
  placeholder,
  type,
  leftIcon,
  defaultValue,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ""}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div className="position-relative auth-pass-inputgroup mb-3">
          <Input
            tabIndex={1}
            name={name}
            className={`input-field ${error ? "invalid-input" : ""}`}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
          {error && <div className="invalid-error">{error.message}</div>}
          {leftIcon}
        </div>
      )}
    />
  );
};

export default TextInputField;
