import React from "react";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search.svg";
import { t } from "i18next";

const SearchBar = ({ lengthRow, value, setValue, maxWidth }) => {
  return (
    <form style={{ width: "100%", maxWidth: maxWidth }}>
      <div className="d-flex justify-content-center align-items-center table-search-bar px-3">
        <input
          onChange={(e) => setValue(e.target.value)}
          type="text"
          className="input-field-search"
          placeholder={`${t(
            "sectionStudents.tableSearchField"
          )} ${lengthRow} ${t("sectionStudents.tableSearchRecord")}`}
          value={value}
        />
        <SearchIcon stroke="#626971" width={18} height={18} />
      </div>
    </form>
  );
};

export default SearchBar;
