import React, { useMemo, useState } from "react";
import { ReactComponent as AddIcon } from "../../../assets/images/icons/plus.svg";
import SectionsRepository from "../../../data/repository/SectionsRepository";
import useSectionsClassroom from "../../../data/hooks/sections/useSectionsClassroom";
import SectionsService from "../../../data/services/SectionsService";
import ConfirmationPopup from "../../../Components/Modals/ConfirmationPopup";
import BasicTable from "../../../Components/Table/BasicTable";
import TableButton from "../../../Components/Table/TableButton";
import EditButton from "../../../Components/Table/EditButton";
import DeleteButton from "../../../Components/Table/DeleteButton";
import { SectionFormSchema } from "../../../utils/validationSchema";
import CreateEditForm from "../../../Components/Forms/CreateEditForm";
import BreadcrumbsBox from "../../../Components/Breadcrumb/BreadcrumbsBox";
import useSchool from "../../../data/hooks/schools/useSchool";
import { ToastMessage } from "../../../Components/Toast/ToastMessage";
import ErrorDisplay from "../../../Components/Error/ErrorDisplay";
import Modal from "../../../Components/Modals/Modal";
import { useParams, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import Loading from "../../../Components/Loading/Loading";
import { useData } from "../../../context/StoreContext";
import { ROLES } from "../../../utils/roles";

function SectionsPage() {
  const { currentUser } = useData();
  const history = useHistory();
  const { school: schoolId, classroom: classId } = useParams();

  const userRole = currentUser?.role?.trim();
  const initialState = { add: false, edit: false, delete: false };

  const [modalState, setModalState] = useState(initialState);
  const [selectedSection, setSelectedSection] = useState(null);

  const { isSchoolLoading, schoolError, schoolData } = useSchool(schoolId);

  const { isSectionsLoading, sectionsError, sectionsData, setData } =
    useSectionsClassroom(classId);

  const { sections, classroom } = sectionsData ?? {};

  const sectionsRepository = new SectionsRepository();
  const sectionsService = new SectionsService(sectionsRepository);

  const AddNewSection = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, add: false });
    try {
      const section = await sectionsService.addSectionParams({
        classId,
        name: data?.name?.ar,
        currentRoundId: classroom?.currentRound,
      });

      setData((prev) => ({ ...prev, sections: [section, ...prev.sections] }));

      ToastMessage.update(toastId, "success", "تم إضافة شعبة جديدة بنجاح");
    } catch (error) {
      console.error("Error adding new school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء إضافة الشعبة");
    }
  };

  const editSection = async (data) => {
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, edit: false });
    try {
      const sectionId = selectedSection.id;
      await sectionsService.updateSectionParams({
        id: sectionId,
        newName: data?.name?.ar,
      });

      const copyData = [...sections];
      const index = copyData.findIndex((section) => section.id === sectionId);

      if (index !== -1) {
        copyData[index].name.ar = data?.name?.ar;
        copyData[index].name.en = data?.name?.ar;
      } else {
        console.warn("Sections with ID", sectionId, "not found");
      }
      setData((prevState) => ({ ...prevState, sections: copyData }));
      ToastMessage.update(toastId, "success", "تم تعديل الشعبة بنجاح");
    } catch (error) {
      console.error("Error adding new school:", error);
      ToastMessage.update(toastId, "error", "حدث خطأ أثناء تعديل الشعبة");
    }
  };

  const deleteSection = async () => {
    const hasStudents = selectedSection?.data?.numberOfStudents > 0;
    const sectionId = selectedSection.id;
    const toastId = ToastMessage.loading("انتظر من فضلك...");
    setModalState({ ...modalState, delete: false });
    const message = "لا يمكن حذف الشعبة لانها تحتوي على طلاب";

    if (hasStudents) {
      ToastMessage.update(toastId, "error", message);
    } else {
      try {
        await sectionsService.deleteSection(sectionId);
        const newData = sections.filter((section) => section.id !== sectionId);
        setData((prevState) => ({ ...prevState, sections: newData }));
        ToastMessage.update(toastId, "success", "تم حذف الشعبة بنجاح");
      } catch (error) {
        console.error("Error deleting section:", error);
        ToastMessage.update(toastId, "error", "حدث خطأ أثناء حذف الشعبة");
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "الشعبة",
        accessorFn: (row) => row?.name?.ar || "-",
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد الطلاب",
        accessorFn: (row) => row?.data?.numberOfStudents || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "عدد التقييمات",
        accessorFn: (row) => row?.data?.numberOfSessions || 0,
        cell: (props) => <span>{props.getValue()}</span>,
      },
      {
        header: "",
        accessorKey: "actions",
        enableSorting: false,
        cell: (props) => (
          <div className="d-flex align-items-center gap-2">
            <EditButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedSection(props.row.original);
                setModalState({ ...modalState, edit: true });
              }}
            />
            <DeleteButton
              className="table-action-buttons"
              onClick={() => {
                setSelectedSection(props.row.original);
                setModalState({ ...modalState, delete: true });
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  if (sectionsError || schoolError) {
    return (
      <ErrorDisplay
        userMessage={"Error while fetching classroom"}
        errorMessage={sectionsError || schoolError}
      />
    );
  }

  if (isSchoolLoading) {
    return <Loading />;
  }

  const breadcrumbsList = [
    ...(userRole == ROLES.SUPER_ADMIN ? [{ label: "المدارس", link: `/` }] : []),
    { label: `${schoolData?.name?.ar || ""}`, link: `/schools/${schoolId}` },
    { label: "الصفوف", link: `/schools/${schoolId}/classrooms` },
    { label: `${classroom?.name?.ar || ""}` },
  ];

  const tdClickHandler = (e, cell) => {
    if (cell.column.id !== "actions") {
      history.push(
        `/schools/${schoolId}/classrooms/${cell.row.original.classroom.id}/sections/${cell.row.original.id}`
      );
    }
  };

  return (
    <div className="page-content">
      <BreadcrumbsBox breadcrumbsList={breadcrumbsList} />
      <Container className="mt-5">
        <BasicTable
          columns={columns}
          data={sections || []}
          handleTdClick={tdClickHandler}
          divClass="container-table mt-4 mb-5"
          tableClass="basic-table row-full-width"
          messageNotFoundResult={"لا يوجد شعب"}
          isLoading={isSectionsLoading}
          isGlobalFilter={true}
          header={
            <TableButton
              text={"اضافة شعبة"}
              icon={<AddIcon width={13} height={13} fill="#fff" />}
              onClick={() => setModalState({ ...modalState, add: true })}
            />
          }
        />

        {/* ---------- Add Class ---------- */}
        <Modal
          open={modalState.add}
          close={() => setModalState({ ...modalState, add: false })}
          title={"اضافة شعبة"}
        >
          <CreateEditForm
            onSubmit={AddNewSection}
            schemaType={SectionFormSchema}
            onCancelClick={() => setModalState({ ...modalState, add: false })}
            inputFields={[
              { name: "name.ar", label: "اسم الشعبة", type: "text" },
            ]}
          />
        </Modal>

        {/* ---------- Edit Class ---------- */}
        <Modal
          open={modalState.edit}
          close={() => setModalState({ ...modalState, edit: false })}
          title={"تعديل الشعبة"}
        >
          <CreateEditForm
            onSubmit={editSection}
            schemaType={SectionFormSchema}
            values={selectedSection}
            onCancelClick={() => setModalState({ ...modalState, edit: false })}
            inputFields={[
              { name: "name.ar", label: "اسم الشعبة", type: "text" },
            ]}
          />
        </Modal>

        {/* ---------- Delete Class ---------- */}
        <ConfirmationPopup
          open={modalState.delete}
          close={() => setModalState({ ...modalState, delete: false })}
          onConfirm={deleteSection}
          title={"حذف الشعبة"}
          subtitle={selectedSection?.name?.ar}
        />
      </Container>
    </div>
  );
}

export default SectionsPage;
