import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Header from "./Header";
import NavHeader from "../Components/Common/Nav";

const Layout = ({ children }) => {
  const [headerClass, setHeaderClass] = useState("");

  const handleScroll = () => {
    const scrollUp = document.documentElement.scrollTop;
    setHeaderClass(scrollUp > 50 ? "topbar-shadow" : "");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);

  return (
    <div dir="rtl" id="layout-wrapper">
      <Header headerClass={headerClass} />
      <NavHeader />
      <div className="main-content">{children}</div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout);
