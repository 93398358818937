import BackendService from "../../service/BackendService";
import { getFirebaseServerTime } from "../../service/FirebaseFunctions";

class SectionsRepository {
  constructor() {
    this.backendService = new BackendService("sections");
  }

  async getManySectionsByIds(ids) {
    const sections = await this.backendService.getMany(ids);
    return sections;
  }

  async getSectionById(id) {
    const section = await this.backendService.getOne(id);
    return section;
  }

  async getSectionsByClassId(classId) {
    const sections = await this.backendService.getManyByFieldId(
      "classroom",
      classId
    );

    return sections;
  }

  async addSection({ classId, name, currentRoundId }) {
    const newSection = {
      name: { ar: name, en: name },
      data: {
        numberOfSessionsPerRound: { [currentRoundId]: 0 },
        numberOfStudents: 0,
      },
      classroom: classId,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    };

    const section = await this.backendService.addOne(newSection);
    return section;
  }
  async updateSection({ id, newName }) {
    await this.collection.doc(id).update({
      name: { ar: newName, en: newName },
      updatedAt: getFirebaseServerTime(),
    });
  }

  async deleteSection(id) {
    await this.collection.doc(id).delete();
  }
}

export default SectionsRepository;
