import ClassRoomsRepository from "../repository/ClassroomsRepository";
import ClassroomsService from "../services/ClassroomsService";
import useFetch from "./utils/useFetch";

const classroomsQuery = Object.freeze({
  byIds: "getClassroomsByIds",
  byId: "getClassroomById",
});

const queryFunctions = {
  getClassroomsByIds: async (classroomService, options) => {
    return await classroomService.getClassroomsByIds(options);
  },
  getClassroomById: async (classroomService, options) => {
    return await classroomService.getClassroomById(options);
  },
};

const fetchData = async (query, options) => {
  const classroomRepository = new ClassRoomsRepository();
  const classroomService = new ClassroomsService(classroomRepository);

  const queryFunction = queryFunctions[query];

  if (!queryFunction) {
    throw new Error("Invalid query");
  }

  return await queryFunction(classroomService, options);
};

const useClassrooms = (query, options) => {
  const {
    data: classroomsData,
    isLoading: isClassroomsLoading,
    error: classroomsError,
  } = useFetch(() => fetchData(query, options));
  return { isClassroomsLoading, classroomsError, classroomsData };
};

export { useClassrooms, classroomsQuery };
