import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const MultiSelect = ({
  control,
  name,
  placeholder,
  options,
  defaultValue,
  isMulti = true,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <div className="mb-3">
          <Select
            id="sections"
            styles={{
              control: (base, state) => ({
                ...base,
                border: state.isFocused
                  ? `1px solid #6ac3db`
                  : error
                  ? "1px solid #ff3333"
                  : "1px solid #C7C7C7",
                outline: state.isFocused ? "1px solid #6ac3db" : "",
                boxShadow: "none",
                padding: "3px 0",
                "&:hover": {
                  borderColor: "none",
                },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? "#6ac3db"
                  : state.isFocused
                  ? "#6AC3DB36"
                  : "#fff",
              }),
            }}
            options={options}
            isMulti={isMulti}
            placeholder={placeholder}
            value={value}
            onChange={(val) => onChange(val)}
          />
          {error && <div className="invalid-error">{error.message}</div>}
        </div>
      )}
    />
  );
};

export default MultiSelect;
