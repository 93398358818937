import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import { convertTimestamp } from "../../utils/convertTimestamp";
import { ReactComponent as UsersIcon } from "../../assets/images/icons/user.svg";
import { ReactComponent as Calender } from "../../assets/images/icons/calender.svg";

const SectionCard = ({ sectionData }) => {
  const { id, data, lastAssessment, name, classroom } = sectionData;
  const dateLastAssessment = convertTimestamp(lastAssessment);

  return (
    <Col xl={3} md>
      <div className="section-card">
        <h3 className="title">
          <Link
            to={`/classrooms/${classroom}/sections/${id}`}
            className="text-dark"
          >
            {name?.ar}
          </Link>
        </h3>
        <div className="flex align-center section-card-content">
          {/* Number of students */}
          <div className="block">
            <span style={{ whiteSpace: "nowrap" }}>عدد الطلاب</span>
            <div className="d-flex justify-content-center align-items-center gap-1">
              <UsersIcon width={18} height={18} stroke="#1C1C1C" />

              <div className="count">{data?.numberOfStudents || 0}</div>
            </div>
          </div>

          {/* Date of last assessment */}
          {lastAssessment && (
            <div className="block">
              <span>اخر تقييم</span>
              <div className="d-flex justify-content-center align-items-center gap-1">
                <Calender width={20} height={20} fill="#1C1C1C" />
                <div className="count">{dateLastAssessment}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};

export default SectionCard;
