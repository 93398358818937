import React from "react";
import { Col, Row } from "reactstrap";
import SectionCard from "./SectionCard";

const ClassCard = ({ classroom, sectionsData }) => {
  const filteredSections = sectionsData
    .filter((section) => section?.classroom === classroom?.id)
    .sort((a, b) => a.name.ar.localeCompare(b.name.ar));

  if (!filteredSections?.length) return;

  return (
    <Row>
      <Col sm={12} className="classroom-card">
        <div className={`p-3 rounded-top mb-3 classroom-header-bg`}>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="mb-0 fs-20 text-dark extra-heading-padding-list-class">
                {classroom?.name?.ar}
              </h5>
            </div>
          </div>
        </div>

        <div className="container-sections">
          <Row className="gy-3">
            {filteredSections.map((section, key) => (
              <SectionCard key={key} sectionData={section} />
            ))}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default ClassCard;
