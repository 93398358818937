const Loading = ({ size = 3.5, color = "#6ac3db" }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={style}
    >
      <div
        className="spinner-border"
        style={{ width: `${size}rem`, height: `${size}rem`, color: color }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
