import BackendService from "../../service/BackendService";

class SchoolsRepository {
  constructor() {
    this.backendService = new BackendService("schools");
  }

  async getSchools(ids) {
    if (ids) {
      return await this.backendService.getMany(ids);
    } else {
      return await this.backendService.getAll();
    }
  }

  async getSchoolById(id) {
    const school = await this.backendService.getOne(id);
    return school;
  }

  async addSchool(params) {
    return await this.backendService.addOne(params);
  }
  async updateSchool(id, postData) {
    await this.backendService.updateOne(id, postData);
  }

  async deleteSchool(id) {
    await this.backendService.deleteOne(id);
  }
}

export default SchoolsRepository;
