import LevelsRepository from "../repository/LevelsRepository";
import LevelsService from "../services/LevelsService";
import useFetch from "./utils/useFetch";

const fetchLevelsData = async () => {
  const levelsRepository = new LevelsRepository();
  const levelsService = new LevelsService(levelsRepository);
  const levels = await levelsService.getLevels();
  return levels;
};

const useLevels = () => {
  const {
    data: levelsData,
    isLoading: isLevelsLoading,
    error: levelsError,
  } = useFetch(() => fetchLevelsData());
  return { isLevelsLoading, levelsError, levelsData };
};

export default useLevels;
