export const getTeacherSectionsLabel = (sections, options) =>
  options?.filter((option) =>
    sections?.some((section) => section === option?.value)
  );

export const calculateTotalClasses = (userSections, sections) => {
  const classSet = new Set();
  userSections?.forEach((sec) => {
    const sectionObj = sections?.find((section) => section?.id === sec?.id);
    if (sectionObj) classSet.add(sectionObj?.classroom?.id);
  });

  return classSet?.size;
};

export const calculateTotalStudents = (userSections, sections) =>
  userSections?.reduce((acc, sec) => {
    const sectionObj = sections?.find((section) => {
      return section?.id === sec?.id;
    });
    return acc + (sectionObj?.data?.numberOfStudents || 0);
  }, 0);
