import { mapSectionsWithClassroomData } from "../../../Mapper/TeacherMapper";
import DatabaseService from "../../../service/DatabaseService";
import FirebaseClassRoomsRepository from "../../repository/ClassroomsRepository";
import SectionsRepository from "../../repository/SectionsRepository";
import ClassroomsService from "../../services/ClassroomsService";
import SectionsService from "../../services/SectionsService";
import useFetch from "../utils/useFetch";

const createRepositories = (db) => ({
  classrooms: new FirebaseClassRoomsRepository(db),
  sections: new SectionsRepository(db),
});

const createServices = (repositories) => ({
  classrooms: new ClassroomsService(repositories.classrooms),
  sections: new SectionsService(repositories.sections),
});

const fetchData = async (classId) => {
  const dbService = new DatabaseService();
  const repositories = createRepositories(dbService.db);
  const services = createServices(repositories);
  const sections = await services.sections.getSectionsByClassId(classId);
  const classroom = await services.classrooms.getClassroomById(classId);

  const sectionData = mapSectionsWithClassroomData(sections, [classroom]);
  return { sections: sectionData, classroom };
};

const useSectionsClassroom = (classId) => {
  const {
    data: sectionsData,
    isLoading: isSectionsLoading,
    error: sectionsError,
    setData,
  } = useFetch(() => fetchData(classId));
  return { isSectionsLoading, sectionsError, sectionsData, setData };
};

export default useSectionsClassroom;
