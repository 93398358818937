import { useMemo } from "react";
import BarChart from "../Charts/BarChart";
const StudentsLevels = ({ studentsList, levelsList }) => {
  const title = "مستوى اداء الطلاب";
  const subtitle = "حسب معيار عربي 21";
  const tooltipName = "عدد الطلاب";

  const studentsPerLevelCount = useMemo(() => {
    if (!studentsList.length) return {};

    return studentsList.reduce((acc, student) => {
      const levelId = student.level;
      acc[levelId] = (acc[levelId] || 0) + 1;
      return acc;
    }, {});
  }, [studentsList]);

  const levelsWithStudentCounts = useMemo(() => {
    if (!levelsList.length) return [];

    return levelsList
      .map((level) => ({
        id: level.id,
        value: studentsPerLevelCount?.[level.id] || 0,
      }))
      .reverse();
  }, [studentsPerLevelCount, levelsList]);

  const startIndex = levelsWithStudentCounts.findIndex(
    (level) => level.value > 0
  );

  const endIndex = levelsWithStudentCounts.findLastIndex(
    (level) => level.value > 0
  );

  const levels = levelsWithStudentCounts.slice(startIndex, endIndex + 1);

  const seriesLength = levels?.length;
  const columnWidthPercent = seriesLength > 10 ? 60 : 6 * seriesLength;

  const getLabel = (value) => {
    const level = levelsList.find((item) => item.id === value);
    return level?.code;
  };

  const style = {
    color: "#6ac3db",
    fontSize: "14px",
    fontWeight: "bold",
    widthColumn: columnWidthPercent + "%",
  };

  return (
    <BarChart
      title={title}
      subtitle={subtitle}
      data={levels}
      getLabel={getLabel}
      style={style}
      tooltipName={tooltipName}
    />
  );
};

export default StudentsLevels;
