const schoolAdminInputs = (state) => {
  return [
    { name: "firstName", label: " الإسم الاول", type: "text" },
    { name: "lastName", label: " الإسم الاخير", type: "text" },
    { name: "email", label: "البريد الالكتروني", type: "email" },
    ...(state.add
      ? [{ name: "password", label: "كلمة المرور", type: "password" }]
      : []),
  ];
};

export default schoolAdminInputs;
