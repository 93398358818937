import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
const NoteCard = ({ title, value, id }) => {
  return (
    <React.Fragment>
      {value && (
        <AccordionItem>
          <AccordionHeader
            className="inner-accordion-item-content"
            targetId={id}
          >
            {title}
          </AccordionHeader>
          <AccordionBody accordionId={id}>{value}</AccordionBody>
        </AccordionItem>
      )}
    </React.Fragment>
  );
};

export default NoteCard;
