import React, { useState } from "react";
import { Label } from "reactstrap";
import TextInputField from "./TextInputField";
import Form from "./Form";
import CustomSelect from "./CustomSelect";
import MultiSelect from "./MultiSelect";
import DatePickerField from "./DatePickerField";
import ShowPasswordButton from "./ShowPasswordButton";

const FormBuilder = ({
  onSubmit,
  inputFields,
  cancel,
  submit,
  schemaType,
  values,
}) => {
  const [passwordType, setPasswordType] = useState("password");

  return (
    <Form onSubmit={onSubmit} schemaType={schemaType} defaultValues={values}>
      {({ control }) => (
        <React.Fragment>
          {inputFields.map((field) => (
            <div key={field.name} className="my-2">
              <Label style={{ fontSize: 18 }}>
                {field.label}{" "}
                {field?.optional && (
                  <span className="input-optional">{"(إختياري)"}</span>
                )}
              </Label>
              {renderInputField(control, field, passwordType, setPasswordType)}
            </div>
          ))}
          <div className="d-flex justify-content-center align-items-center gap-4 mt-4">
            {cancel}
            {submit}
          </div>
        </React.Fragment>
      )}
    </Form>
  );
};

export default FormBuilder;

const renderInputField = (control, field, passwordType, setPasswordType) => {
  switch (field.type) {
    case "select":
      return <CustomSelect control={control} {...field} />;
    case "multi-select":
      return <MultiSelect control={control} {...field} />;
    case "date":
      return <DatePickerField control={control} {...field} />;
    case "password":
      return (
        <TextInputField
          control={control}
          {...field}
          type={passwordType}
          leftIcon={
            <ShowPasswordButton type={passwordType} setType={setPasswordType} />
          }
        />
      );
    default:
      return <TextInputField control={control} {...field} />;
  }
};
