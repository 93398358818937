import React from "react";
import { Col } from "reactstrap";
import ProgressBar from "@ramonak/react-progress-bar";

const LinearProgressBar = ({ title, color, value }) => {
  return (
    <Col lg>
      <div className="block-wrapper-inner-item block-wrapper-inner-item--stats">
        <h4>{title}</h4>
        <ProgressBar
          bgColor={color}
          completed={value !== undefined ? value : 0}
          labelAlignment={"outside"}
          labelColor={"#878a99"}
          dir="rtl"
          //   customLabel={value !== undefined ? value : 0}
        />
      </div>
    </Col>
  );
};

export default LinearProgressBar;
