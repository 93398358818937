import React from "react";
import AudioPlayer from "react-h5-audio-player";
import playIcon from "../../../assets/images/play.svg";
import pauseIcon from "../../../assets/images/pause.svg";
import "react-h5-audio-player/lib/styles.css";

const StudentAudioRecording = ({ title, src }) => {
  if (!src) return;

  const customIcons = {
    play: <img src={playIcon} alt="Play" style={iconStyle} />,
    pause: <img src={pauseIcon} alt="Pause" style={iconStyle} />,
  };

  return (
    <div className="block-wrapper-inner-item">
      <h4>{title}</h4>
      <div dir="ltr">
        <AudioPlayer
          src={src}
          layout="horizontal"
          customVolumeControls={[]}
          customAdditionalControls={[]}
          showJumpControls={false}
          customIcons={customIcons}
        />
      </div>
    </div>
  );
};

const iconStyle = {
  height: "23px",
  position: "relative",
  top: "-3px",
};

export default StudentAudioRecording;
