import React from "react";
import errorIcon from "../../assets/images/icons/exclamation.svg";
const ErrorDisplay = ({ userMessage, errorMessage }) => {
  console.error("Error in fetchData:", errorMessage);

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center flex-"
      style={{ height: "60vh" }}
    >
      <img src={errorIcon} alt="error-icon" style={{ width: "5rem" }} />
      <div className={"mt-4 fs-4"}>{userMessage}</div>
    </div>
  );
};

export default ErrorDisplay;
