import firebase from "firebase/compat/app";
import "firebase/compat/storage";

class FirebaseStorageService {
  constructor() {
    this.storage = firebase.storage();
    this.storageRef = this.storage.ref();
  }

  getDownloadURL = async (path) => {
    if (!path || path === "") return null;

    try {
      const url = await this.storageRef.child(path).getDownloadURL();
      return url;
    } catch (error) {
      console.error("Error getting download photo:", error);
      throw error;
    }
  };

  uploadFile = (path, file) => {
    if (!file || file === "") return null;

    return new Promise((resolve, reject) => {
      const profileRef = this.storage.ref(path);
      const upload = profileRef.put(file);
      upload.on(
        "state_changed",
        (snapShot) => resolve(snapShot?.ref?.fullPath),
        (error) => reject(error)
      );
    });
  };
}

export default FirebaseStorageService;
