import React, { useState } from "react";
import { Card, Col, Container, Input, Label, Row } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { generateSlug } from "../../utils/generateSlug";
import defaultAvatar from "../../assets/images/users/user-dummy-img.jpg";
import { useData } from "../../context/StoreContext";
import Form from "../../Components/Forms/Form";
import { EditProfileFormSchema } from "../../utils/validationSchema";
import TextInputField from "../../Components/Forms/TextInputField";
import BreadcrumbsBox from "../../Components/Breadcrumb/BreadcrumbsBox";
import UserImage from "../../Components/Image/UserImage";
import RoundedButton from "../../Components/Buttons/RoundedButton";
import { ToastMessage } from "../../Components/Toast/ToastMessage";
import UsersRepository from "../../data/repository/UsersRepository";
import UsersService from "../../data/services/UsersService";
import FirebaseStorageService from "../../service/FirebaseStorageService";
import { ReactComponent as CameraIcon } from "../../assets/images/icons/camera.svg";

import { t } from "i18next";

const EditProfilePage = () => {
  document.title = t("editProfile.pageTitle");
  const history = useHistory();
  const params = useParams();

  const { currentUser } = useData();
  const [imageUrl, setImageUrl] = useState(currentUser?.imageLink);
  const [imageFile, setImageFile] = useState();

  const handleUploadProfilePic = (e) => {
    if (e.target.files || e.target.files[0]) {
      setImageFile(e.target.files[0]);
      const objUrl = URL.createObjectURL(e.target.files[0]);
      setImageUrl(objUrl);
    }
  };

  const onSubmit = async (data) => {
    try {
      const fullName = `${data.firstName} ${data.lastName}`;
      const path = `/profilePic/${generateSlug(fullName)}/${imageFile?.name}`;
      const storageService = new FirebaseStorageService();
      const newPath = await storageService.uploadFile(path, imageFile);

      const userId = params.user;
      const updatedData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        currentEmail: currentUser.email,
        lastUpdate: new Date(),
        ...(newPath && { photoUrl: newPath }),
      };
      const userRepository = new UsersRepository();
      const userService = new UsersService(userRepository);
      await userService.updateCurrentUser(userId, updatedData);
      ToastMessage.success(t("editProfile.editMsg"));
      setTimeout(() => history.goBack(), 3000);
    } catch (error) {
      ToastMessage.error(t("sectionStudents.deleteStudentValidation"));
      console.error(error);
    }
  };

  const breadcrumbsList = [
    { label: t("editProfile.pageTitle") },
    { label: `${currentUser?.firstName} ${currentUser?.lastName}` },
  ];

  return (
    <div className="page-content">
      <BreadcrumbsBox breadcrumbsList={breadcrumbsList} />
      <Container style={{ marginTop: "80px" }}>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={10}>
            <Card className="edit-profile">
              <div className="p-4 mx-lg-5 mx-md-4 my-3">
                <div className="text-center">
                  <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                    <UserImage
                      src={imageUrl ? imageUrl : defaultAvatar}
                      className={"rounded-circle img-thumbnail"}
                    />
                    <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                      <Input
                        id="profile-img-file-input"
                        type="file"
                        className="profile-img-file-input"
                        onChange={handleUploadProfilePic}
                      />
                      <Label
                        htmlFor="profile-img-file-input"
                        className="profile-photo-edit avatar-xs"
                      >
                        <div
                          className="rounded-circle bg-light d-flex justify-content-center align-items-center"
                          style={{ width: 30, height: 30 }}
                        >
                          <CameraIcon width={25} height={25} fill="#6ac3db" />
                        </div>
                      </Label>
                    </div>
                  </div>
                </div>
                <Form
                  onSubmit={onSubmit}
                  schemaType={EditProfileFormSchema}
                  defaultValues={{
                    firstName: currentUser.firstName,
                    lastName: currentUser.lastName,
                    email: currentUser.email,
                  }}
                >
                  {({ control }) => {
                    return (
                      <Row>
                        <Col lg={12}>
                          {/* firstName Field */}
                          <Label className="form-label" htmlFor="firstName">
                            {t("NewStudent.firstName")}
                          </Label>
                          <TextInputField
                            control={control}
                            name={"firstName"}
                            placeholder={t(
                              "NewStudent.validationNewStudentFirstName"
                            )}
                            type={"text"}
                          />
                        </Col>
                        <Col lg={12}>
                          {/* lastName Field */}
                          <Label className="form-label" htmlFor="lastName">
                            {t("NewStudent.lastName")}
                          </Label>
                          <TextInputField
                            control={control}
                            name={"lastName"}
                            placeholder={t(
                              "NewStudent.validationNewStudentLastName"
                            )}
                            type={"text"}
                          />
                        </Col>

                        <Col lg={12}>
                          {/* email Field */}
                          <Label className="form-label" htmlFor="lastName">
                            {"البريد الالكتروني"}
                          </Label>
                          <TextInputField
                            control={control}
                            name={"email"}
                            placeholder={t("Signin.usernamePlaceholder")}
                            type={"text"}
                          />
                        </Col>

                        <Col lg={12}>
                          {/* Button */}
                          <div className="mt-5 d-flex justify-content-center">
                            <RoundedButton
                              bgColor="#6ac3db"
                              maxWidth="20rem"
                              text={t("editProfile.update")}
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  }}
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditProfilePage;
