import React, { useState } from "react";
import { Accordion } from "reactstrap";
import NoteCard from "./NoteCard";

const TeacherNotes = ({ title, accuracy, comprehension }) => {
  const [isOpen, setIsOpen] = useState("");

  const handleToggle = (id) => setIsOpen(isOpen === id ? "" : id);

  const data = [
    { text: "القراءة", accordionId: "2", value: accuracy },
    { text: " الفهم والاستيعاب", accordionId: "1", value: comprehension },
  ];

  if (!accuracy && !comprehension) return;

  return (
    <div className="block-wrapper-inner-item">
      <h5>{title}</h5>
      <Accordion
        className="inner-accordion-items-content"
        open={isOpen}
        toggle={handleToggle}
      >
        {data.map((item, index) => (
          <NoteCard
            key={index}
            title={item.text}
            value={item.value}
            id={item.accordionId}
          />
        ))}
      </Accordion>
    </div>
  );
};

export default TeacherNotes;
