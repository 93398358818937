import HomePage from "../pages/Home/index";
import LoginPage from "../pages/Auth/Login/LoginPage";
import EditProfilePage from "../pages/EditProfile";
import ResetPasswordPage from "../pages/Auth/ResetPassword/ResetPasswordPage";
import Basic404 from "../pages/Auth/Errors/Basic404";
import Schools from "../pages/Schools/Schools";
import SectionPage from "../pages/Section";
import StudentPage from "../pages/Student";
import SchoolHomePage from "../pages/Schools/Home/HomeSchool";
import TeacherSections from "../pages/Schools/Teachers/TeacherSections";
import SectionsPage from "../pages/Schools/Classrooms/SectionsPage";
import { ROLES } from "../utils/roles";

const authProtectedRoutes = [
  {
    path: "/",
    exact: true,
    component: HomePage,
    allowedRoles: Object.values(ROLES),
  },
  { path: "/classrooms", component: HomePage, allowedRoles: [ROLES.TEACHER] },
  {
    path: "/classrooms/:classroom/sections/:section",
    component: SectionPage,
    allowedRoles: Object.values(ROLES),
  },
  {
    path: "/classrooms/:classroom/sections/:section/students/:student",
    component: StudentPage,
    allowedRoles: Object.values(ROLES),
  },
  {
    path: "/users/:user/edit",
    component: EditProfilePage,
    allowedRoles: Object.values(ROLES),
  },
  {
    path: "/schools",
    component: Schools,
    allowedRoles: [ROLES.SUPER_ADMIN],
  },
  {
    path: "/schools/:school/teacher/:teacher",
    component: TeacherSections,
    allowedRoles: [ROLES.SUPER_ADMIN, ROLES.SCHOOL_ADMIN],
  },
  {
    path: "/schools/:school/classrooms/:classroom",
    component: SectionsPage,
    allowedRoles: [ROLES.SUPER_ADMIN, ROLES.SCHOOL_ADMIN],
  },
  {
    path: "/schools/:school/teachers",
    component: SchoolHomePage,
    allowedRoles: [ROLES.SUPER_ADMIN, ROLES.SCHOOL_ADMIN],
  },
  {
    path: "/schools/:school/classrooms",
    component: SchoolHomePage,
    allowedRoles: [ROLES.SUPER_ADMIN, ROLES.SCHOOL_ADMIN],
  },
  {
    path: "/schools/:school/students",
    component: SchoolHomePage,
    allowedRoles: [ROLES.SUPER_ADMIN, ROLES.SCHOOL_ADMIN],
  },
  {
    path: "/schools/:school/classrooms/:classroom/sections/:section",
    component: SectionPage,
    allowedRoles: Object.values(ROLES),
  },
  {
    path: "/schools/:school/classrooms/:classroom/sections/:section/students/:student",
    component: StudentPage,
    allowedRoles: Object.values(ROLES),
  },
  {
    path: "/schools/:school",
    component: SchoolHomePage,
    allowedRoles: [ROLES.SUPER_ADMIN, ROLES.SCHOOL_ADMIN],
  },
  { path: "*", component: Basic404, allowedRoles: Object.values(ROLES) },
];

// Public Routes
const publicRoutes = [
  { path: "/login", component: LoginPage },
  { path: "/pass-reset", component: ResetPasswordPage },
];

export { authProtectedRoutes, publicRoutes };
