import React from "react";
import { ReactComponent as ArrowLastLeft } from "../../assets/images/icons/arrow-last-left-icon.svg";
import { ReactComponent as ArrowLastRight } from "../../assets/images/icons/arrow-last-right-icon.svg";
import { ReactComponent as ArrowLeft } from "../../assets/images/icons/arrow-left-icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/icons/arrow-right-icon.svg";
import PaginationButton from "./PaginationButton";

const TablePagination = ({ table, pageIndex, pageSize, isLoading }) => {
  return (
    <div className="pagination-table d-flex justify-content-between m-3 pt-3">
      <div className="d-flex align-items-center gap-1">
        <span>
          {"الصفحة"}
          <strong>{` ${pageIndex + 1} من ${table.getPageCount()} `}</strong>
        </span>
        <span>
          {"| اذهب للصفحة: "}
          <input
            type="number"
            className="goto-page"
            disabled={isLoading}
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              const goToPage = page >= table.getPageCount() ? pageIndex : page;
              table.setPageIndex(goToPage);
            }}
          />
        </span>

        <select
          value={pageSize}
          disabled={isLoading}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              عرض {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className="d-flex gap-1">
        <PaginationButton
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage() || isLoading}
          icon={<ArrowLastRight />}
        />
        <PaginationButton
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage() || isLoading}
          icon={<ArrowRight />}
        />
        <PaginationButton
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage() || isLoading}
          icon={<ArrowLeft />}
        />
        <PaginationButton
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage() || isLoading}
          icon={<ArrowLastLeft />}
        />
      </div>
    </div>
  );
};

export default TablePagination;
