class ClassroomsService {
  constructor(classroomRepository) {
    this.classroomRepository = classroomRepository;
  }

  async getClassroomById(id) {
    const classroom = await this.classroomRepository.getClassroomById(id);
    return classroom;
  }

  async getClassroomsByIds(ids) {
    const classrooms = await this.classroomRepository.getClassroomsByIds(ids);
    return classrooms;
  }

  async getClassroomsBySchoolId(id) {
    const classrooms = await this.classroomRepository.getClassroomsBySchoolId(
      id
    );
    return classrooms;
  }

  async addClassroom(schoolId, name) {
    const classroom = await this.classroomRepository.addClassroom(
      schoolId,
      name
    );
    return classroom;
  }

  async updateClassroom(id, newName) {
    await this.classroomRepository.updateClassroom(id, newName);
  }

  async deleteClassroom(id) {
    await this.classroomRepository.deleteClassroom(id);
  }
}
export default ClassroomsService;
