import BackendService from "../../service/BackendService";

class RolesRepository {
  constructor() {
    this.backendService = new BackendService("roles");
  }

  async findOne(id) {
    const role = await this.backendService.getOne(id);
    return role;
  }

  async findByRole(role) {
    const filterObj = {
      role: role,
    };

    const roles = await this.backendService.getManyByFilter(filterObj);
    return roles;
  }
}

export default RolesRepository;
